export const applicationsNavigationConfig: Record<string, { addNewPathname: string, addNewTooltip: string }> = {
  dealroadshow: {
    addNewPathname: '/create',
    addNewTooltip: 'Create Roadshow',
  },
  dealvdr: {
    addNewPathname: '/create',
    addNewTooltip: 'Create Data Room',
  },
  evercall: {
    addNewPathname: '/create',
    addNewTooltip: 'Schedule Evercall',
  },
  verisend: {
    addNewPathname: '/create',
    addNewTooltip: 'Create VeriSend',
  },
  researchroom: {
    addNewPathname: '/create',
    addNewTooltip: 'Create ResearchRoom',
  },
  allocate: {
    addNewPathname: '/upload',
    addNewTooltip: 'Upload Allocations',
  },
  '17g5': {
    addNewPathname: '/create',
    addNewTooltip: 'Create Data Room',
  },
  investorset: {
    addNewPathname: '/create',
    addNewTooltip: 'Create InvestorSet',
  },
};
