import React, { ReactElement, useState, useRef, useEffect, useMemo, useCallback, ChangeEvent } from 'react';
import cn from 'classnames';
import Highlighter from 'react-highlight-words';
import { Input, Icon, IconType, Tooltip } from '@dealroadshow/uikit';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { RoadshowsCollectionItem } from '@/allocate/domain/vo/Allocations/Upload/NameAndLink';
import { IFinalFormFieldInputComponentProps } from '@/Framework/UI/Organisms/FinalForm/interfaces';
import styles from '@/allocate/ui/components/shared/Upload/NameAndLink/LinkedRoadshow/linkedRoadshow.scss';
import inputStyles from '@dealroadshow/uikit/dist/lib/components/Forms/Input/input.scss';

interface IProps extends IFinalFormFieldInputComponentProps {
  getRoadshowsList: (search: string) => void,
  roadshowsList: RoadshowsCollectionItem[],
  className: string,
  dataTest: string,
  onChange: (name: string) => void,
}

const LinkedRoadshow = ({
  getRoadshowsList,
  roadshowsList,
  className,
  ...props
}: IProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const inputRef = useRef<HTMLDivElement>(null);

  const {
    setLinkedRoadshowName,
    setLinkedRoadshowId,
  } = useUploadContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!inputRef.current.contains(event.target)) {
        setIsDropdownOpened(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, []);

  const label = useMemo(() => (
    <span className={ styles.label }>
      Link to deal roadshow (optional)
      <span className={ styles.questionIcon }>
        <Tooltip
          content="You will not be able to link to a roadshow where you are an Analytics Only Deal Manager"
        >
          <Icon type={ IconType.question } />
        </Tooltip>
      </span>
    </span>
  ), []);

  const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value || '';
    props.input.onChange(value);

    if (value === '') {
      setLinkedRoadshowId(null);
      setLinkedRoadshowName(null);
      setIsDropdownOpened(false);
    }

    if (value.length < 3) {
      setIsDropdownOpened(false);
    }

    if (value.length >= 3) {
      getRoadshowsList(value.trim());
      setIsDropdownOpened(true);
    }
  }, []);

  const handleSelectRoadshow = (id: string, name: string) => {
    props.input.onChange(name);
    props.onChange(name);
    setLinkedRoadshowId(id);
    setLinkedRoadshowName(name);
    setIsDropdownOpened(false);
  };

  const renderDropdown = () => {
    let dropdownContent: ReactElement | ReactElement[] = (
      <div className={ styles.zeroCase }>No Matching Roadshows</div>
    );

    if (roadshowsList.length) {
      dropdownContent = roadshowsList.map((option) => {
        const { alreadyLinked, analyticsOnly, id, name } = option;
        const searchValue = props.input.value.trim();

        if (analyticsOnly) {
          return (
            <div
              className={ cn(styles.option, styles.disabledOption) }
              key={ id }
            >
              <Highlighter
                autoEscape
                searchWords={ [searchValue] }
                textToHighlight={ name }
                highlightClassName={ inputStyles.matchingText }
              />
              { ' (Analytics Only)' }
            </div>
          );
        }

        if (alreadyLinked) {
          return (
            <div
              className={ cn(styles.option, styles.disabledOption) }
              key={ id }
            >
              <Highlighter
                autoEscape
                searchWords={ [searchValue] }
                textToHighlight={ name }
                highlightClassName={ inputStyles.matchingText }
              />
              { ' (Already Linked)' }
            </div>
          );
        }

        return (
          <div
            className={ styles.option }
            key={ id }
            onClick={ () => handleSelectRoadshow(id, name) }
          >
            <Highlighter
              autoEscape
              searchWords={ [searchValue] }
              textToHighlight={ name }
              highlightClassName={ inputStyles.matchingText }
            />
          </div>
        );
      });
    }

    return (
      <div className={ styles.dropdown }>
        { dropdownContent }
      </div>
    );
  };

  return (
    <div className={ styles.inputWrapper } ref={ inputRef }>
      { /* @ts-ignore */ }
      <Input
        name={ props.input.name }
        label={ label }
        placeholder="Deal Roadshow Name"
        inputClassName={ cn({
          [styles.openedInput]: isDropdownOpened,
        }) }
        input={ {
          ...props.input,
          onChange,
        } }
        dataTest={ props.dataTest }
        isNarrow
        className={ className }
      />
      { isDropdownOpened && renderDropdown() }
    </div>
  );
};

export default LinkedRoadshow;
