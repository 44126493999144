import useRouter from '@/Framework/hooks/useNextRouter';
import RoadshowAnalytics from '@/dealroadshow/ui/components/dmPortal/Analytics/Roadshow';
import RoadshowAnalyticsAllocationsTab
  from '@/allocate/ui/components/Dealroadshow/Allocations/AllocationsTab';

const ANALYTICS_ALLOCATIONS_PATH = '/roadshow/[roadshowId]/analytics/allocations';

const RoadshowAnalyticsLayout = ({ children }) => {
  const { pathname } = useRouter();

  const content = pathname.startsWith(ANALYTICS_ALLOCATIONS_PATH)
    ? <RoadshowAnalyticsAllocationsTab>{ children }</RoadshowAnalyticsAllocationsTab>
    : children;

  return (
    <RoadshowAnalytics>
      { content }
    </RoadshowAnalytics>
  );
};

export default RoadshowAnalyticsLayout;
