import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import { Checkbox } from '@dealroadshow/uikit';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FilterItem from '@/Framework/UI/Organisms/Filters/components/FilterItem';
import FilterColumns from '@/Framework/UI/Organisms/Filters/components/FilterColumns';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: string[],
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (section: string) => void,
}

const filterByName = 'includeAccountTypeIds';

function AccountType({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const { accountTypeFilterList } = useGlobalAnalyticsFiltersContext();

  const [
    children,
    activeAccountTypeIds,
  ] = useMemo(
    () => [
      accountTypeFilterList?.allIds.map((accountTypeId) => ({
        value: accountTypeId,
        label: accountTypeFilterList?.byId[`${ accountTypeId }`]?.accountTypeTitle,
        disabled: !accountTypeFilterList?.byId[`${ accountTypeId }`]?.canChangeResponse,
      })),
      accountTypeFilterList?.allIds
        .filter((accountTypeId) => accountTypeFilterList?.byId[accountTypeId]?.canChangeResponse),
    ],
    [accountTypeFilterList?.allIds],
  );

  const accountTypeIdsUnchecked = useMemo(
    () => formValues?.filter((transactionTypeId) => !activeAccountTypeIds.includes(transactionTypeId)),
    [formValues],
  );

  const indeterminateState = useMemo(() => getIndeterminateState(children, formValues), [children, formValues]);

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        form.change(filterByName, [...formValues, ...activeAccountTypeIds]);
      } else {
        form.change(filterByName, accountTypeIdsUnchecked);
      }
    };
    return (
      <Field
        name={ sectionId }
        component={ ({ meta, input }) => (
          <Checkbox
            { ...input }
            meta={ meta }
            dataTest={ sectionId }
            label={ sectionTitle }
            onChange={ handleFilterSectionChange }
            disabled={ activeAccountTypeIds.length === 0 }
            supportIndeterminate
            { ...indeterminateState }
          />
        ) }
      />
    );
  }, [form, accountTypeFilterList?.allIds, indeterminateState]);

  if (isEmpty(accountTypeFilterList?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { children.map(({ value, label, disabled }) => (
          <FilterItem key={ value }>
            <Field
              name={ filterByName }
              component={ ({ meta, input }) => (
                <Checkbox
                  dataTest={ `accountType ${ label } checkbox` }
                  { ...input }
                  meta={ meta }
                  value={ value }
                  disabled={ disabled }
                  checked={ formValues?.includes(value) }
                  onChange={ (event) => {
                    const { checked } = event.target;
                    if (checked) {
                      form.change(filterByName, [...formValues, value]);
                    } else {
                      form.change(filterByName, formValues.filter((id) => id !== value));
                    }
                  } }
                  label={ label }
                />
              ) }
            />
          </FilterItem>
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default AccountType;
