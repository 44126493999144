import React, { useEffect, useMemo } from 'react';
import { RadioGroup, Radio } from '@dealroadshow/uikit';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import { useInvestorTargetingContext } from '@/dealroadshow/application/dmPortal/analytics/InvestorTargetingContext';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import styles from './ComparableLevel.scss';

interface IProps {
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (id: string) => void,
  fieldComponent: any,
}

const ComparableLevel = ({ sectionId, sectionTitle, fieldComponent: Field, isVisible, onSectionToggle }: IProps) => {
  const { comparableLevel } = useFiltersContext();
  const { isInvestorTargetingPage } = useInvestorTargetingContext();

  useEffect(() => {
    if ((!isInvestorTargetingPage && isVisible) || (isInvestorTargetingPage && !isVisible)) {
      onSectionToggle(sectionId);
    }
  }, [isInvestorTargetingPage]);

  const headerComponent = useMemo(
    () => (
      <>
        <span>{ sectionTitle }</span>
        <span className={ styles.subtitle }>Impacts Targeting analytics, digests and exports</span>
      </>
    ),
    [sectionTitle],
  );

  const defaultValue = comparableLevel.find((level) => level.default)?.value ?? null;

  if (comparableLevel.length === 0) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <Field name={ sectionId } defaultValue={ defaultValue }>
        { ({ input: { onChange, value } }) => (
          <RadioGroup
            name={ sectionId }
            selectedValue={ value }
            onChange={ onChange }
            dataTest={ sectionId }
            className={ styles.radioGroup }
            isNarrow
          >
            { comparableLevel.map((item) => (
              <Radio key={ item.value } dataTest={ item.value } value={ item.value } label={ item.name } />
            )) }
          </RadioGroup>
        ) }
      </Field>
    </FilterSection>
  );
};

export default ComparableLevel;
