import React, { createContext, useContext, useEffect, useCallback, useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import Application from '@/dmPortal/domain/Application';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext';
import { findAvailableTenant, tenantsConfig } from './config';
import useDataroomHasAccess from '@/dataroom/application/globalAnalytics/useHasAccess';
import config from '@/Framework/config';

const relativePath = (path: string): string => `/analytics/${ path }`;

const useGlobalAnalytics = () => {
  const router = useRouter();
  const { applicationCollection, isCollectionFetched } = useNavigationContext();
  const currentRoute = router.asPath.split('/')[2];
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    getHasAccessApplications: getDataroomHasAccessApplications,
    availableApplications,
    isFetched: isAccessFetched,
  } = useDataroomHasAccess();

  const isTenantActive = useCallback(
    (tenantCode: string): boolean => (
        Application.isTenantActivated(tenantCode, applicationCollection) &&
        (tenantsConfig[tenantCode]?.isDataroom ? availableApplications[tenantCode] : true)
      ),
    [applicationCollection, availableApplications],
  );

  useEffect(() => {
    getDataroomHasAccessApplications();
  }, []);

  useEffect(() => {
    if (!isCollectionFetched || !isAccessFetched) {
      return;
    }

    const isCurrentTenantActive = !!tenantsConfig[currentRoute] && (
      tenantsConfig[currentRoute].code === config.tenant.allocate.code
        ? isTenantActive(tenantsConfig.roadshows.code)
        : isTenantActive(tenantsConfig[currentRoute].code)
    );

    if (!isCurrentTenantActive) {
      const availableTenant = findAvailableTenant(applicationCollection, availableApplications);

      router
        .push(relativePath(availableTenant?.path || tenantsConfig.allocate.path))
        .then(() => setIsInitialized(true));
      return;
    }

    setIsInitialized(true);
  }, [isCollectionFetched, isTenantActive, isAccessFetched]);

  const isAllocationsPage = currentRoute === tenantsConfig.allocate.path;

  const dealsSupportedTenants = Object.values(tenantsConfig)
    .filter(({ code }) => isTenantActive(code))
    .map(({ code }) => code);

  return {
    isTenantActive,
    relativePath,
    isAllocationsPage,
    dealsSupportedTenants,
    availableApplications,
    isInitialized,
  };
};

type GlobalAnalyticsContextType = ReturnType<typeof useGlobalAnalytics>;

const GlobalAnalyticsContext = createContext<GlobalAnalyticsContextType>(null);

export const useGlobalAnalyticsContext = () => {
  const context = useContext(GlobalAnalyticsContext);
  if (!context) {
    throw new Error('useGlobalAnalyticsContext must be used within a GlobalAnalyticsContext');
  }
  return context;
};

interface IProps {
  children?: React.ReactNode,
}

const GlobalAnalyticsContextProvider = ({ children }: IProps) => (
  <GlobalAnalyticsContext.Provider value={ useGlobalAnalytics() }>{ children }</GlobalAnalyticsContext.Provider>
);

export default GlobalAnalyticsContextProvider;
