import cn from 'classnames';
import Head from 'next/head';
import { Link } from '@/Framework/Router/Next/Link';
import Image404 from './assets/404.svg';

import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import spacesStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/spaces.scss';
import styles from '@/Framework/UI/Pages/PageNotFound/pageNotFound.scss';

interface IProps {
  className?: string,
  title?: string,
  content?: React.ReactNode,
  button?: React.ReactNode,
}

const PageNotFound = ({
  className = '',
  title = 'Page not found',
  content = 'The page you are looking for could not be found.',
  button = (
    <Link to="/">
      <Button
        variant={ variantTypes.text }
        className={ spacesStyles.mtxl }
        title="Back to Homepage"
        dataTest="backToHomePage"
      />
    </Link>
  ),
}: IProps) => (
  <>
    <Head>
      <title>{ title }</title>
    </Head>
    <div className={ cn(styles.container, className) }>
      <img
        className={ styles.backgroundImage }
        alt="Page not found"
        src={ Image404 }
      />

      <h1 className={ cn(spacesStyles.mtxl, styles.title) }>
        Whoops...
      </h1>
      <h2 className={ cn(spacesStyles.mtxl, styles.subTitle) }>
        { content }
      </h2>
      { button }
    </div>
  </>
);

export default PageNotFound;
