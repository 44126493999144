import RoadshowAnalyticsRepository from '@/dealroadshow/infrastructure/repository/analytics/RoadshowAnalyticsRepository';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import downloadFile from '@/Framework/api/downloadFile';
import * as actionTypes from './actionTypes';
import * as roadshowSelectors from '@/dealroadshow/application/actions/data/selectors';

/**
 * @param {String} roadshowId
 * @param {Object} filters
 * @param {Object} targetingFilters
 * @return {Promise}
 */
export function downloadExcel(roadshowId, filters, targetingFilters) {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.REQUEST_DOWNLOAD_EXCEL });
    try {
      const timeZone = roadshowSelectors.getRoadshowTimeZone(getState());
      const roadshowAnalyticsRepository = getState().container.get(RoadshowAnalyticsRepository);
      const url = await roadshowAnalyticsRepository.downloadExcel(roadshowId, filters, targetingFilters, timeZone);
      downloadFile(url);
      dispatch({ type: actionTypes.REQUEST_DOWNLOAD_EXCEL_SUCCESS });
    } catch (errorResponse) {
      dispatch({ type: actionTypes.REQUEST_DOWNLOAD_EXCEL_ERROR });
      NotificationManager.error(getErrorMessage(errorResponse));
    }
  };
}
