import React, { useEffect } from 'react';
import cn from 'classnames';
import noop from 'lodash/noop';
import flatten from 'lodash/flatten';
import Underwriters, { ITitles as IUnderwritersTitle } from '@/Framework/UI/Organisms/EnhancedTagging/Field/Underwriters';
import Company from '@/Framework/UI/Organisms/EnhancedTagging/Field/Company';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import DealSize from '@/Framework/UI/Organisms/EnhancedTagging/Field/DealSize';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import AccountSuggestModal, { useAccountSuggestModal } from './AccountSuggestModal';
import { Clearfix } from '@dealroadshow/uikit';
import {
  ENHANCED_TAGGING_SECTION_NAME,
  SPONSOR_FIELD_NAME,
  SUBSECTOR_FIELD_NAME,
  SUBINDUSTRY_FIELD_NAME,
  COUNTRY_FIELD_NAME,
  DEALSIZE_FIELD_NAME,
  CURRENCY_FIELD_NAME,
} from './constants';
import cardStyles from '@dealroadshow/uikit/dist/lib/styles/card.scss';
import styles from './EnhancedTagging.scss';

const getPrefixedName = (name) => `${ ENHANCED_TAGGING_SECTION_NAME }.${ name }`;

interface IProps {
  isSectorVisible?: boolean,
  isDealSizeEnabled?: boolean,
  titles?: {
    companyPlaceholder?: string,
    sponsorName?: string,
    currencyId?: string,
    subsectorId?: string,
    subindustryId?: string,
    countryId?: string,
    dealSize?: string,
    underwriters?: IUnderwritersTitle,
  },
  fieldComponent: React.ComponentType<any>,
  fieldArrayComponent: React.ComponentType<any>,
  countries: any,
  currencies: any,
  industries: any,
  sectors: any,
  companies: any,
  isDisabled?: boolean,
  underwritersWrapperClassName?: string,
  underwritersRowClassName?: string,
  wrapperClassName?: string,
  selectStyles?: object,
  suggestNewAccount?: {
    setFieldValue: (name: string, value: any) => void,
    saveHandler: (accountName: string, suggestFor: string) => void,
  },
  onSuggestionSelected?: (val: string, fieldName: string) => void,
}

const EnhancedTagging = (
  {
    titles = {},
    isSectorVisible,
    isDealSizeEnabled = true,
    fieldComponent: Field,
    fieldArrayComponent,
    countries,
    currencies,
    industries,
    sectors,
    companies,
    underwritersWrapperClassName,
    underwritersRowClassName,
    isDisabled,
    wrapperClassName,
    selectStyles,
    suggestNewAccount,
    onSuggestionSelected = noop,
  }: IProps,
) => {
  const {
    collection: countriesCollection,
    getCountriesList,
  } = countries;
  const {
    collection: currenciesCollection,
    getCurrenciesList,
  } = currencies;
  const {
    collection: industriesCollection,
    getIndustriesList,
  } = industries;
  const {
    collection: sectorsCollection,
    getSectorsList,
  } = sectors;

  const countriesOptions = countriesCollection.map((option) => ({
    value: option.id,
    label: option.name,
  }));
  const currenciesOptions = currenciesCollection.map((option) => ({
    value: option.id,
    label: `${ option.name } (${ option.displayName })`,
  }));
  const subIndustryOptions = flatten(
    industriesCollection.map((industry) => industry.subindustryList.map((subIndustry) => ({
      value: subIndustry.id,
      label: `${ industry.name } - ${ subIndustry.name }`,
    }))),
  );
  const subSectorsOptions = flatten(
    sectorsCollection.map((sector) => sector.subsectorList.map((subSector) => ({
      value: subSector.id,
      label: `${ sector.name } - ${ subSector.name }`,
    }))),
  );

  useEffect(() => {
    getCountriesList();
    getCurrenciesList();
    getIndustriesList();
  }, []);

  useEffect(() => {
    isSectorVisible && !subSectorsOptions.length && getSectorsList();
  }, [isSectorVisible]);

  const isSuggestModalEnable = Boolean(suggestNewAccount);

  const {
    visibleModal,
    modalInputValue,
    openCompanyModal,
    openUnderwriterModal,
    closeModal,
  } = useAccountSuggestModal({
    setFieldValue: suggestNewAccount?.setFieldValue,
  });

  return (
    <>
      <div data-test="enhancedTaggingForm" className={ wrapperClassName }>
        <div className={ cn(cardStyles.cardInnerHorizontal, styles.card) }>
          <div className={ styles.formInputWrp }>
            <TextField
              label={ titles.sponsorName || 'company / sponsor' }
              component={ Company }
              // @ts-ignore
              id={ SPONSOR_FIELD_NAME }
              name={ getPrefixedName(SPONSOR_FIELD_NAME) }
              placeholder={ titles.companyPlaceholder || 'Company Name' }
              className={ styles.formInputWrp }
              fieldComponent={ Field }
              companies={ companies }
              dataTest={ SPONSOR_FIELD_NAME }
              disabled={ isDisabled }
              modalCall={ isSuggestModalEnable }
              modalCallText="Don’t see your Company?"
              modalShow={ openCompanyModal }
              onSuggestionSelected={ onSuggestionSelected }
            />
          </div>
          { isSectorVisible && (
            <Field
              label={ titles.subsectorId || 'ABS SECTOR & SUBSECTOR' }
              name={ getPrefixedName(SUBSECTOR_FIELD_NAME) }
              placeholder="ABS Sector & Subsector"
              className={ styles.formInputWrp }
              component={ FinalFormSelect }
              selectStyles={ selectStyles }
              simpleValue
              asterisk
              options={ subSectorsOptions }
              dataTest="enhancedTaggingSubsectorIdFormSelect"
              disabled={ isDisabled }
            />
          ) }
          <Field
            label={ titles.subindustryId || 'industry & subindustry' }
            name={ getPrefixedName(SUBINDUSTRY_FIELD_NAME) }
            placeholder="Company Industry & Subindustry"
            className={ styles.formInputWrp }
            component={ FinalFormSelect }
            selectStyles={ selectStyles }
            simpleValue
            asterisk
            options={ subIndustryOptions }
            dataTest="enhancedTaggingSubindustryIdFormSelect"
            disabled={ isDisabled }
          />
          <Field
            label={ titles.countryId || 'Country' }
            name={ getPrefixedName(COUNTRY_FIELD_NAME) }
            placeholder="Deal Country"
            className={ styles.formInputWrp }
            component={ FinalFormSelect }
            selectStyles={ selectStyles }
            simpleValue
            asterisk
            options={ countriesOptions }
            dataTest="enhancedTaggingCountryIdFormSelect"
            disabled={ isDisabled }
          />
          { isDealSizeEnabled && (
            <DealSize
              name={ getPrefixedName(DEALSIZE_FIELD_NAME) }
              label={ titles.dealSize }
              className={ cn(
                styles.formInputWrp,
                styles.formInputWrpSmall,
                styles.formInputWrpSmallMargin,
              ) }
              fieldComponent={ Field }
              dataTest="enhancedTaggingDealSize"
              isDisabled={ isDisabled }
            />
          ) }
          <Field
            label={ titles.currencyId || 'Currency' }
            name={ getPrefixedName(CURRENCY_FIELD_NAME) }
            placeholder="Deal Currency"
            className={ cn(styles.formInputWrp, { [styles.formInputWrpSmall]: isDealSizeEnabled }) }
            component={ FinalFormSelect }
            selectStyles={ selectStyles }
            simpleValue
            asterisk
            options={ currenciesOptions }
            dataTest="enhancedTaggingCurrencyIdFormSelect"
            disabled={ isDisabled }
          />
          <Clearfix />
        </div>
        <Underwriters
          titles={ titles.underwriters }
          fieldArrayComponent={ fieldArrayComponent }
          fieldComponent={ Field }
          companies={ companies }
          wrapper={ underwritersWrapperClassName }
          rowClassName={ underwritersRowClassName }
          disabled={ isDisabled }
          modalCall={ isSuggestModalEnable }
          modalCallText="Don’t see your Underwriter?"
          modalShow={ openUnderwriterModal }
          onSuggestionSelected={ onSuggestionSelected }
        />
      </div>
      { suggestNewAccount && (
        <AccountSuggestModal
          visibleModal={ visibleModal }
          modalInputValue={ modalInputValue }
          handleSaveAccount={ suggestNewAccount.saveHandler }
          closeModal={ closeModal }
        />
      ) }
    </>
  );
};

export default EnhancedTagging;
