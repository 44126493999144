import React from 'react';
import { useSelector } from 'react-redux';
import { startOfDay } from 'date-fns';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import * as roadshowSelectors from '@/dealroadshow/application/actions/data/selectors';
import FiltersBtn from '@/Framework/UI/Organisms/Filters/FiltersPanel/FiltersBtn';
import { getFiltersByKeysWithValues } from '@/Framework/UI/Organisms/Filters/helpers';
import { removableFilterKeys } from './constants';

interface IProps {
  className: string,
  onClick: () => void,
}

const FilterButton = ({ className, onClick }: IProps) => {
  const {
    appliedFilters,
    dates: { startedAt },
  } = useFiltersContext();
  const timeZone = useSelector(roadshowSelectors.getRoadshowTimeZone);

  const filters = {
    ...appliedFilters,
    includeInvestorIds: [...(appliedFilters?.includeAccountIds || []), ...(appliedFilters?.includeInvestorIds || [])],
  };

  const dateFromMin = startedAt && getUnixTimestamp(startOfDay(utcToZonedDate(timeZone, startedAt)));

  return (
    <FiltersBtn
      className={ className }
      onClick={ onClick }
      filtersCount={ Object.keys(getFiltersByKeysWithValues(filters, removableFilterKeys, dateFromMin)).length }
    />
  );
};

export default FilterButton;
