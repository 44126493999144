export function getRoadshowTimeZone(state) {
  return state.roadshow.data.itemSettings.timeZone?.timeZone;
}

export function getInvestorTargetingEnabled(state) {
  return state.roadshow.data.itemSettings.investorTargetingEnabled;
}

export function getRoadshow(state) {
  return state.roadshow.data.item;
}
