import {
  indexCellCallback,
  currencyCellCallback,
  numberCellCallback,
  displayNameCellCallback,
} from '@/allocate/ui/components/shared/Tables/cellCallbacks';
import {
  classSubscriptionLevelCellCallback,
  greenShoeCellCallback,
  proceedsCellCallback,
  rangeCellCallback,
  sharePriceCellCallback,
} from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/cellCallbacks';
import { IEquityTableColumnsConfig } from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/interfaces';
import { IColumn } from '@dealroadshow/uikit';
import alignStyles from '@dealroadshow/uikit/dist/lib/styles/helpers/align.scss';

const columns = ({
   hasOrders,
   hasRangeLow,
   hasRangeHigh,
 }: IEquityTableColumnsConfig): IColumn[] => ([
  {
    name: 'index',
    title: '#',
    tooltipContent: 'Reference Number',
    width: 38,
    cellCallback: indexCellCallback,
  },
  {
    name: 'className',
    title: 'Class',
    tooltipContent: 'Class Name',
  },
  {
    name: 'currency',
    title: 'CCY',
    tooltipContent: 'Currency',
    cellCallback: currencyCellCallback,
  },
  {
    name: 'rangeLow',
    title: 'Range Low',
    tooltipContent: 'Low Range of Pricing Guidance',
    className: alignStyles.alignRight,
    isVisible: hasRangeLow,
    cellCallback: rangeCellCallback,
  },
  {
    name: 'rangeHigh',
    title: 'Range High',
    tooltipContent: 'High Range of Pricing Guidance',
    className: alignStyles.alignRight,
    isVisible: hasRangeHigh,
    cellCallback: rangeCellCallback,
  },
  {
    name: 'finalSharePrice',
    title: 'Offer Price',
    className: alignStyles.alignRight,
    cellCallback: sharePriceCellCallback,
  },
  {
    name: 'allocationShares',
    title: 'Offer # Shares',
    className: alignStyles.alignRight,
    cellCallback: numberCellCallback,
  },
  {
    name: 'proceeds',
    title: 'Proceeds',
    tooltipContent: '',
    className: alignStyles.alignRight,
    cellCallback: proceedsCellCallback,
  },
  {
    name: 'equityExchange',
    title: 'Exchange',
    tooltipContent: '',
    className: alignStyles.alignRight,
    cellCallback: displayNameCellCallback,
  },
  {
    name: 'subscriptionLevel',
    title: 'SUBX',
    tooltipContent: 'Subscription Level',
    className: alignStyles.alignRight,
    isVisible: hasOrders,
    cellCallback: classSubscriptionLevelCellCallback,
  },
  {
    name: 'greenShoe',
    title: 'Green Shoe',
    className: alignStyles.alignCenter,
    cellCallback: greenShoeCellCallback,
  },
]);

export default columns;
