import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { components as ReactSelectComponents } from 'react-select';

import {
  useGlobalAnalyticsFiltersContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import { IAppliedFilters } from '@/dmPortal/application/GlobalAnalytics/filters/types';
import useFilterMethods from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/useFilterMethods';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import styles from './account.scss';

interface IProps {
  sectionTitle: string,
  sectionId: string,
  isVisible: boolean,
  onSectionToggle: () => void,
  fieldComponent: React.ElementType,
  filtersPayload: IAppliedFilters,
}

const filterByName = 'includeAccountIds';

const Account = (props) => {
  const {
    sectionId,
    sectionTitle,
    isVisible,
    onSectionToggle,
    fieldComponent: Field,
    filtersPayload,
  }: IProps = props;
  const {
    accountFilterList,
    getAccountFilters,
    isFetching,
    isAccountFetching,
  } = useGlobalAnalyticsFiltersContext();
  const {
    isFirstRender,
    formatOptionLabel,
    handleSearchChange,
    handleOnScrollBottom,
  } = useFilterMethods(filtersPayload, getAccountFilters, accountFilterList);

  const headerComponent = () => (
    <label data-test={ sectionId } htmlFor={ sectionId } className={ styles.labelWrapper }>
      <div className={ styles.title }>
        { sectionTitle }
      </div>
    </label>
  );

  const options = useMemo(() => accountFilterList?.map((account) => {
    return {
      value: `${ account?.accountId }`,
      label: `${ account?.accountName }`,
      canChangeResponse: account?.canChangeResponse,
    };
  }), [accountFilterList]);

  const abbreviationValueRenderer = ({ data, ...otherProps }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      { data.label }
    </ReactSelectComponents.MultiValueLabel>
  );

  if (isEmpty(accountFilterList) && isFirstRender.current) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent() }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <Field
        name={ filterByName }
        component={ FinalFormSelect }
        placeholder="Select Account"
        options={ options }
        isMulti
        components={ {
          MultiValueLabel: abbreviationValueRenderer,
        } }
        isNarrow
        hideSelectedOptions={ false }
        closeMenuOnSelect={ false }
        dataTest="FilterCompanyParentSelect"
        onInputChange={ handleSearchChange }
        isLoading={ isFetching && isAccountFetching }
        formatOptionLabel={ formatOptionLabel }
        isOptionDisabled={ (option) => (isFetching && isAccountFetching) || !option.canChangeResponse }
        onMenuScrollToBottom={ handleOnScrollBottom }
      />
    </FilterSection>
  );
};

export default Account;
