import Router from 'next/router';
import UploadExcel from '@/allocate/ui/components/shared/Upload/UploadExcel';
import ConfirmSecurityDetails from '@/allocate/ui/components/shared/Upload/ConfirmSecurityDetails';
import ConfirmAllocations from '@/allocate/ui/components/shared/Upload/ConfirmAllocations';
import LinkAccounts from '@/allocate/ui/components/shared/Upload/LinkAccounts';
import NameAndLink from '@/allocate/ui/components/shared/Upload/NameAndLink';
import DealManagers from '@/allocate/ui/components/shared/Upload/DealManagers';
import EnhancedTagging from '@/allocate/ui/components/shared/Upload/EnhancedTagging';
import AddOwner from '@/allocate/ui/components/shared/Upload/AddOwner';
import { tenantAllocate, tenantDealroadshow } from '@/allocate/application/config';
import { AllocateTenants } from '@/allocate/domain/vo/common/AllocateTenants';
import { IStep, ISteps } from '@/allocate/domain/vo/Allocations/Upload/Upload';

export const UPLOAD_MAX_SIZE = 5242880;
export const DRS_REMAP_URL = 'edit/link-account-names';

export const actions = {
  upload: 'upload',
  edit: 'edit',
};

export const isActionInvalid = (action) => !Object.values(actions).includes(action);

export const steps: ISteps = {
  uploadExcel: {
    url: 'upload-excel',
    title: 'Upload Excel',
    Component: UploadExcel,
  },
  confirmSecurityDetails: {
    url: 'confirm-security-details',
    title: 'Confirm Security Details',
    Component: ConfirmSecurityDetails,
  },
  confirmAllocations: {
    url: 'confirm-allocations',
    title: 'Confirm Allocations',
    Component: ConfirmAllocations,
  },
  linkAccounts: {
    url: 'link-account-names',
    title: 'Link Account Names',
    Component: LinkAccounts,
  },
  nameAndLink: {
    url: 'name-and-link',
    title: 'Name & Link',
    Component: NameAndLink,
  },
  dealManagers: {
    url: 'deal-managers',
    title: 'Add Deal Managers',
    Component: DealManagers,
  },
  enhancedTagging: {
    url: 'enhanced-tagging',
    title: 'Enhanced Tagging',
    Component: EnhancedTagging,
  },
  addOwner: {
    url: 'add-owner',
    title: 'Add Owner',
    Component: AddOwner,
  },
};

const commonSteps: IStep[] = [
  steps.uploadExcel,
  steps.confirmSecurityDetails,
  steps.confirmAllocations,
  steps.linkAccounts,
];

export const drsSteps: IStep[] = [
  ...commonSteps,
  steps.addOwner,
];

export const allocateSteps: IStep[] = [
  ...commonSteps,
  steps.nameAndLink,
  steps.dealManagers,
  steps.enhancedTagging,
];

export const allocateEditUrls = [
  steps.linkAccounts.url,
  steps.nameAndLink.url,
  steps.dealManagers.url,
  steps.enhancedTagging.url,
];

export const getAllocateUploadUrl = (
  step: IStep,
  isReimport: boolean = false,
  dealAllocationId?: string,
) => (
  isReimport ? `/allocate/upload/${ step.url }?reimportFrom=${ dealAllocationId }` : `/allocate/upload/${ step.url }`
);

export const getDrsUploadUrl = (step: IStep, roadshowId: string) => (
  `/roadshow/${ roadshowId }/analytics/allocations/upload/${ step.url }`
);

export const getAllocateEditUrl = (step: IStep, dealAllocationId: string, from?: string) => {
  const queryFrom = from ? `?from=${ from }` : '';
  return `/allocate/${ dealAllocationId }/edit/${ step.url }${ queryFrom }`;
};

export const getDrsEditUrl = (step: IStep, roadshowId: string) => (
  `/roadshow/${ roadshowId }/analytics/allocations/edit/${ step.url }`
);

export const getAllocateAnalyticsUrl = (dealAllocationId: string) => (
  `/allocate/${ dealAllocationId }/analytics`
);

export const getDrsAnalyticsUrl = (roadshowId: string) => (
  `/roadshow/${ roadshowId }/analytics/allocations`
);

export const getUploadUrl = (
  tenant: AllocateTenants,
  step: IStep,
  roadshowId?: string,
): string => {
  if (tenant === tenantAllocate) {
    return getAllocateUploadUrl(step);
  }

  return getDrsUploadUrl(step, roadshowId);
};

export const changeEditingStep = async (
  step: IStep,
  dealAllocationId: string,
  from: string,
): Promise<void> => {
  const fromPage = from === 'analytics' ? from : '';
  const url = getAllocateEditUrl(step, dealAllocationId, fromPage);
  const as = getAllocateEditUrl(step, dealAllocationId);
  await Router.push(url, as);
};

export const getCancelEditingUrl = (
  tenant: AllocateTenants,
  from: string,
  dealAllocationId: string,
  roadshowId?:string,
): string => {
  if (tenant === tenantDealroadshow) {
    return getDrsAnalyticsUrl(roadshowId);
  }

  if (from === 'analytics') {
    return getAllocateAnalyticsUrl(dealAllocationId);
  }

  return '/allocate';
};
