import { v4 as uuid } from 'uuid';
import * as actionTypes from './actionTypes';

const initialState = {
  isFetching: false,
  isRefreshModalVisible: false,
  isTryToPublish: false,
  isSubmitDraftRoadshow: false,
  isSuccessModalVisible: false,
  isSubsectorFieldVisible: false,
  uploadError: {
    type: {
      presentation: {
        error: 0,
        warning: 0,
      },
      documents: {
        warning: 0,
      },
      commercialVideo: {
        warning: 0,
      },
      logo: {
        warning: 0,
      },
    },
    all: {
      error: 0,
      warning: 0,
    },
  },
  isNotifyContacts: false,
  initialFormValues: {
    entryCodeRotatingInitialCollection: [],
    entryCodeRotatingInitialMap: {},
    entryCodeRotatingCollection: [],
    entryCodeRotatingMap: {},
    uploadDocumentsList: [],
    uploadCommercialVideoList: [],
    dealManagers: [],
    externalLinks: [],
    whitelistUsersAndDomains: [],
    internalNotes: '',
    timeZoneApplied: {
      id: '',
      timeZone: 'America/New_York',
      abbr: 'ET',
    },
    reviewEntryCodeEnabled: false,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CREATE_ROADSHOW_ID:
      return {
        ...state,
        createRoadshowId: uuid(),
      };
    case actionTypes.TOGGLE_ROADSHOW_FORM_PUBLISH:
      return {
        ...state,
        isTryToPublish: action.payload,
      };
    case actionTypes.TOGGLE_ROADSHOW_FORM_NOTIFY_ALL_CONTACTS:
      return {
        ...state,
        isNotifyContacts: action.payload,
      };
    case actionTypes.IS_SUBMIT_DRAFT_ROADSHOW:
      return {
        ...state,
        isSubmitDraftRoadshow: action.payload,
      };
    case actionTypes.REQUEST_ROADSHOW_CREATE:
    case actionTypes.REQUEST_ROADSHOW_UPDATE:
    case actionTypes.ROADSHOW_FORM_INIT:
      return {
        ...state,
        isFetching: true,
      };
    case actionTypes.REQUEST_ROADSHOW_CREATE_SUCCESS:
    case actionTypes.REQUEST_ROADSHOW_UPDATE_SUCCESS:
    case actionTypes.REQUEST_ROADSHOW_CREATE_ERROR:
    case actionTypes.REQUEST_ROADSHOW_UPDATE_ERROR:
    case actionTypes.ROADSHOW_FORM_INIT_ERROR:
    case actionTypes.ROADSHOW_FORM_INIT_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case actionTypes.SET_ROADSHOW_NEED_TO_REFRESH_MODAL_VISIBLE:
      return {
        ...state,
        isRefreshModalVisible: action.payload,
        isFetching: false,
      };
    case actionTypes.ROADSHOW_UPLOAD_VALIDATION_ERROR:
      return {
        ...state,
        uploadError: action.payload,
      };
    case actionTypes.RESET_ROADSHOW_UPLOAD_VALIDATION:
    case actionTypes.ROADSHOW_UPLOAD_VALIDATION_SUCCESS:
      return {
        ...state,
        uploadError: {
          ...initialState.uploadError,
        },
      };
    case actionTypes.ROADSHOW_FORM_INITIAL_VALUES_CHANGE:
      return {
        ...state,
        initialFormValues: {
          ...action.payload,
        },
        isFetching: false,
      };
    case actionTypes.SHOW_SUCCESS_MODAL:
      return {
        ...state,
        isSuccessModalVisible: true,
      };
    case actionTypes.HIDE_SUCCESS_MODAL:
      return {
        ...state,
        isSuccessModalVisible: false,
      };
    case actionTypes.SHOW_SUBSECTOR_FIELD:
      return {
        ...state,
        isSubsectorFieldVisible: true,
      };
    case actionTypes.HIDE_SUBSECTOR_FIELD:
      return {
        ...state,
        isSubsectorFieldVisible: false,
      };
    case actionTypes.RESET_ROADSHOW_FORM:
      return initialState;
    default:
      return state;
  }
}
