import React, { ReactNode } from 'react';
import Button from '@/Framework/UI/Atoms/Button';
import {
  Icon,
  IconType,
  Dropdown,
  DropdownItem,
  DropdownDivider,
  DropdownItemType,
} from '@dealroadshow/uikit';
import { Link } from '@/Framework/Router/Next/Link';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import { useRoadshowLayoutContext } from '../RoadshowLayout/RoadshowLayoutContext';
import styles from './RoadshowMenu.scss';

interface IProps {
  children?: ReactNode,
  isAdminAnalyticsOnly: boolean,
  roadshowId: string,
}

export const getItemDataTest = (name: string): string => `roadshowMenu-${ name }`;

const RoadshowMenu = ({ children, isAdminAnalyticsOnly, roadshowId }: IProps) => {
  const { tabMenuContent } = useRoadshowLayoutContext();

  const menuContent = (
    <>
      { !isAdminAnalyticsOnly && (
        <DropdownItem
          type={ DropdownItemType.nextNavLink }
          iconType={ IconType.settings }
          to={ `/roadshow/${ roadshowId }/edit` }
          dataTest={ getItemDataTest('settings') }
          NextNavLinkComponent={ Link }
        >
          Roadshow Settings
        </DropdownItem>
      ) }
      <DropdownItem
        type={ DropdownItemType.nextNavLink }
        iconType={ IconType.pieChart }
        to={ `/roadshow/${ roadshowId }/analytics` }
        dataTest={ getItemDataTest('analytics') }
        NextNavLinkComponent={ Link }
      >
        View Analytics
      </DropdownItem>
      <DropdownItem
        type={ DropdownItemType.link }
        iconType={ IconType.dealroadshow }
        href={ drsUrl.getUrl(`/manager/${ roadshowId }/preview`) }
        dataTest={ getItemDataTest('roadshowPreview') }
      >
        View Roadshow
      </DropdownItem>
      { children && (
        <>
          <DropdownDivider />
          { children }
        </>
      ) }
      { tabMenuContent }
    </>
  );

  return (
    <Dropdown
      dataTest="roadshowDropdownMenu"
      trigger={ (
        <Button variant="outline" className={ styles.button } dataTest="roadshowMenuToggleButton">
          <Icon type={ IconType.hamburgerMenu } />
        </Button>
    ) }
    >
      { menuContent }
    </Dropdown>
  );
};

export default RoadshowMenu;
