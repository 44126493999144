import { Dispatch } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import useRouter from '@/Framework/hooks/useNextRouter';
import isEqual from 'lodash/isEqual';
import isDefined from '@/Framework/dataHelpers/isDefined';
import { transactionTypes } from '@/dealroadshow/domain/TransactionTypes';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import RoadshowCrossDealAnalyticsRepository
  from '@/dealroadshow/infrastructure/repository/analytics/RoadshowCrossDealAnalyticsRepository';
import SessionStorageRepository from '@/Framework/browser/storage/SessionStorageRepository';
import createAction from '@/Framework/State/Redux/createAction';
import { NotificationManager } from '@/Framework/Notification';
import * as filtersSelectors from './selectors';
import {
  IReducerState,
  IGeneralFiltersPayload,
  IAppliedAllocationsFilters,
  IAllocationFiltersPayload,
  IAppliedFilters,
  IAppliedGeneralFilters,
} from './types';
import * as actionTypes from './actionTypes';
import { GLOBAL_ANALYTICS_TIMEZONE } from '../config';
import { getDateFrom } from './getDateFrom';

const CROSS_DEAL_SESSION_STORAGE_KEY = 'crossDealAnalytics';

export const useActions = (dispatch: Dispatch<any>, state: IReducerState) => {
  const { replace } = useRouter();
  const { container } = useDIContext();

  const getCrossDealFilters = async (filters: IAppliedFilters) => {
    dispatch(createAction(actionTypes.GET_CROSS_DEAL_FILTERS, filters));
    try {
      const roadshowCrossDealAnalyticsRepository = container.get(RoadshowCrossDealAnalyticsRepository);
      const response = await roadshowCrossDealAnalyticsRepository.getCrossDealFilters(filters, {
        timeZone: GLOBAL_ANALYTICS_TIMEZONE,
      });
      dispatch(createAction(actionTypes.GET_CROSS_DEAL_FILTERS_SUCCESS, response));
    } catch (err) {
      dispatch(createAction(actionTypes.GET_CROSS_DEAL_FILTERS_ERROR, err));
    }
  };

  const getAccountFilters = async (filters: IAppliedFilters, page = 1, perPage = 100, search = '') => {
    dispatch(
      createAction(page === 1 ? actionTypes.GET_ACCOUNT_FILTERS : actionTypes.GET_MORE_ACCOUNT_FILTERS, filters),
    );
    try {
      const roadshowCrossDealAnalyticsRepository = container.get(RoadshowCrossDealAnalyticsRepository);
      const response = await roadshowCrossDealAnalyticsRepository.getAccountFilters(filters, {
        timeZone: GLOBAL_ANALYTICS_TIMEZONE,
        page,
        perPage,
        search,
      });
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_ACCOUNT_FILTERS_SUCCESS : actionTypes.GET_MORE_ACCOUNT_FILTERS_SUCCESS,
          response,
        ),
      );
    } catch (err) {
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_ACCOUNT_FILTERS_ERROR : actionTypes.GET_MORE_ACCOUNT_FILTERS_ERROR,
          err,
        ),
      );
    }
  };

  const getSponsorFilters = async (filters: IAppliedFilters, page = 1, perPage = 100, search = '') => {
    dispatch(
      createAction(page === 1 ? actionTypes.GET_SPONSOR_FILTERS : actionTypes.GET_MORE_SPONSOR_FILTERS, filters),
    );
    try {
      const roadshowCrossDealAnalyticsRepository = container.get(RoadshowCrossDealAnalyticsRepository);
      const response = await roadshowCrossDealAnalyticsRepository.getSponsorFilters(filters, {
        timeZone: GLOBAL_ANALYTICS_TIMEZONE,
        page,
        perPage,
        search,
      });
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_SPONSOR_FILTERS_SUCCESS : actionTypes.GET_MORE_SPONSOR_FILTERS_SUCCESS,
          response,
        ),
      );
    } catch (err) {
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_SPONSOR_FILTERS_ERROR : actionTypes.GET_MORE_SPONSOR_FILTERS_ERROR,
          err,
        ),
      );
    }
  };

  const getUnderwriterFilters = async (filters: IAppliedFilters, page = 1, perPage = 100, search = '') => {
    dispatch(
      createAction(
        page === 1 ? actionTypes.GET_UNDERWRITER_FILTERS : actionTypes.GET_MORE_UNDERWRITER_FILTERS,
        filters,
      ),
    );
    try {
      const roadshowCrossDealAnalyticsRepository = container.get(RoadshowCrossDealAnalyticsRepository);
      const response = await roadshowCrossDealAnalyticsRepository.getUnderwriterFilters(filters, {
        timeZone: GLOBAL_ANALYTICS_TIMEZONE,
        page,
        perPage,
        search,
      });
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_UNDERWRITER_FILTERS_SUCCESS : actionTypes.GET_MORE_UNDERWRITER_FILTERS_SUCCESS,
          response,
        ),
      );
    } catch (err) {
      dispatch(
        createAction(
          page === 1 ? actionTypes.GET_UNDERWRITER_FILTERS_ERROR : actionTypes.GET_MORE_UNDERWRITER_FILTERS_ERROR,
          err,
        ),
      );
    }
  };

  const getLocalFiltersState = (): any => {
    const sessionStorageRepository = container.get<SessionStorageRepository>(SessionStorageRepository);
    const state = JSON.parse(sessionStorageRepository.getItem(CROSS_DEAL_SESSION_STORAGE_KEY));
    if (state) {
      return state.filters;
    }

    return undefined;
  };

  const saveLocalFiltersState = (filters: object): void => {
    const sessionStorageRepository = container.get(SessionStorageRepository);
    const state = JSON.parse(sessionStorageRepository.getItem(CROSS_DEAL_SESSION_STORAGE_KEY));
    const initialState = {
      filters,
    };

    if (state) {
      const newState = {
        ...state,
        filters,
      };
      sessionStorageRepository.setItem(CROSS_DEAL_SESSION_STORAGE_KEY, JSON.stringify(newState));
      return;
    }

    sessionStorageRepository.setItem(CROSS_DEAL_SESSION_STORAGE_KEY, JSON.stringify(initialState));
  };

  const removeLocalFiltersState = () => {
    const sessionStorageRepository = container.get(SessionStorageRepository);
    const state = JSON.parse(sessionStorageRepository.getItem(CROSS_DEAL_SESSION_STORAGE_KEY));

    if (state) {
      const newState = { ...state };
      delete newState.filters;
      return sessionStorageRepository.setItem(CROSS_DEAL_SESSION_STORAGE_KEY, JSON.stringify(newState));
    }

    return sessionStorageRepository.removeItem(CROSS_DEAL_SESSION_STORAGE_KEY);
  };

  const payloadChange = (payload: object) => dispatch(createAction(actionTypes.CROSS_DEAL_FILTERS_PAYLOAD, payload));

  const appliedFiltersChange = (payload: object) => (
    dispatch(createAction(actionTypes.CROSS_DEAL_FILTERS_APPLY, payload))
  );

  const allocationPayloadChange = (payload: object) => (
    dispatch(createAction(actionTypes.CROSS_DEAL_ALLOCATION_FILTERS_PAYLOAD, payload))
  );

  const getInitialState = (): IAppliedGeneralFilters => {
    const dateFrom = getDateFrom(filtersSelectors.getFirstLoginAt(state));
    return {
      dateFrom,
      dateTo: null,
      includeTransactionTypeIds: [],
      includeTransactionTypeTenants: [],
      includeAccountTypeIds: [],
      includeAccountIds: [],
      includeAllocationIds: [],
      includeRoadshowIds: [],
      includeEvercallIds: [],
      includeAbsSectorIds: [],
      includeAbsSubSectorIds: [],
      includeIndustryIds: [],
      includeSubIndustryIds: [],
      includeSponsorIds: [],
      includeUnderwriterIds: [],
      includeDealvdrIds: [],
      includeInvestorSetIds: [],
      includeVeriSendIds: [],
      include17g5Ids: [],
      excludeTransactionTypeIds: [transactionTypes.OtherExcludedAnalytics],
    };
  };

  const getAllocationInitialState = (): IAppliedAllocationsFilters => {
    const allocationRange = filtersSelectors.getAllocationFilterRange(state);
    const spreadSizeRange = filtersSelectors.getSpreadFilterRange(state);
    const tenorWalYearsRange = filtersSelectors.getTenorWalYearsFilterRange(state);
    const yieldSizeRange = filtersSelectors.getYieldFilterRange(state);
    return {
      allocationRange: {
        range: {
          min: allocationRange?.range?.min || 0,
          max: allocationRange?.range?.max || 0,
        },
        minValue: allocationRange?.range?.min || 0,
        maxValue: allocationRange?.range?.max || 0,
      },
      spreadSizeRange: {
        range: {
          min: spreadSizeRange?.range?.min || 0,
          max: spreadSizeRange?.range?.max || 0,
        },
        minValue: spreadSizeRange?.range?.min || 0,
        maxValue: spreadSizeRange?.range?.max || 0,
      },
      tenorWalYearsRange: {
        range: {
          min: tenorWalYearsRange?.range?.min || 0,
          max: tenorWalYearsRange?.range?.max || 0,
        },
        minValue: tenorWalYearsRange?.range?.min || 0,
        maxValue: tenorWalYearsRange?.range?.max || 0,
      },
      yieldSizeRange: {
        range: {
          min: yieldSizeRange?.range?.min || 0,
          max: yieldSizeRange?.range?.max || 0,
        },
        minValue: yieldSizeRange?.range?.min || 0,
        maxValue: yieldSizeRange?.range?.max || 0,
      },
      includeCurrencyIds: [],
      includeRatingGroupIds: [],
      includeRatingAgencyIds: [],
      includeDebtCouponTypeIds: [],
      upsized: false,
    };
  };

  const getFiltersPayload = (values: IAppliedGeneralFilters): IGeneralFiltersPayload => {
    const industryFilterList = filtersSelectors.getIndustryFilterList(state);
    const subIndustryFilterList = filtersSelectors.getSubIndustryFilterList(state);
    const absSectorFilterList = filtersSelectors.getAbsSectorFilterList(state);
    const absSubSectorFilterList = filtersSelectors.getAbsSubSectorFilterList(state);

    let {
      dateFrom,
      dateTo,
      includeTransactionTypeIds,
      includeSubIndustryIds,
      includeAbsSubSectorIds,
      includeAccountTypeIds,
      includeAccountIds,
      includeAllocationIds,
      includeRoadshowIds,
      includeEvercallIds,
      includeSponsorIds,
      includeUnderwriterIds,
      includeDealvdrIds,
      includeInvestorSetIds,
      includeVeriSendIds,
      include17g5Ids,
    } = values;
    let includeIndustryIds = [];
    let includeAbsSectorIds = [];

    includeSubIndustryIds.forEach((subIndustryId) => {
      const { industryId } = subIndustryFilterList.byId[subIndustryId];
      const { subIndustryIds } = industryFilterList.byId[industryId];
      const subIndustryIdsChecked = subIndustryIds.every((value) => includeSubIndustryIds.includes(value));
      if (subIndustryIdsChecked) {
        if (!includeIndustryIds.includes(industryId)) {
          includeIndustryIds.push(industryId);
        }
        includeIndustryIds.forEach(() => {
          includeSubIndustryIds = includeSubIndustryIds.filter(
            (value) => !subIndustryIds.includes(value) && value !== subIndustryId,
          );
        });
      }
    });

    includeAbsSubSectorIds.forEach((absSubSectorId) => {
      const { absSectorId } = absSubSectorFilterList.byId[absSubSectorId];
      const subSectorIds = absSectorFilterList.byId[absSectorId]?.subSectorIds;
      const subSectorIdsChecked = subSectorIds.every((value) => includeAbsSubSectorIds.includes(value));
      if (subSectorIdsChecked) {
        if (!includeAbsSectorIds.includes(absSectorId)) {
          includeAbsSectorIds.push(absSectorId);
        }
        includeAbsSectorIds.forEach(() => {
          includeAbsSubSectorIds = includeAbsSubSectorIds.filter(
            (value) => !subSectorIds.includes(value) && value !== absSubSectorId,
          );
        });
      }
    });

    return {
      dateFrom,
      dateTo,
      includeTransactionTypeIds,
      includeAbsSectorIds,
      includeAbsSubSectorIds,
      includeIndustryIds,
      includeSubIndustryIds,
      includeAccountTypeIds,
      includeAccountIds: includeAccountIds.map(({ value }) => value),
      includeAllocationIds,
      includeRoadshowIds,
      includeEvercallIds,
      includeDealvdrIds,
      includeInvestorSetIds,
      includeVeriSendIds,
      include17g5Ids,
      includeSponsorIds: includeSponsorIds.map(({ value }) => value),
      includeUnderwriterIds: includeUnderwriterIds.map(({ value }) => value),
      excludeTransactionTypeIds: [transactionTypes.OtherExcludedAnalytics],
    };
  };

  const isInitialRangeCheck = (rangeValue: number = null, value: number = null): number | null => {
    if (!isDefined(value) || isEqual(rangeValue, value)) {
      return null;
    }
    return value;
  };

  const getAllocationFiltersPayload = (values: IAppliedAllocationsFilters): IAllocationFiltersPayload => {
    let {
      allocationRange,
      spreadSizeRange,
      tenorWalYearsRange,
      yieldSizeRange,
      includeCurrencyIds,
      includeRatingGroupIds,
      includeRatingAgencyIds,
      includeDebtCouponTypeIds,
      upsized,
    } = values;

    return {
      includeCurrencyIds: includeCurrencyIds.map(({ value }) => value),
      includeRatingGroupIds,
      includeRatingAgencyIds,
      includeDebtCouponTypeIds,
      allocationFrom: isInitialRangeCheck(allocationRange?.minValue, allocationRange?.range.min),
      allocationTo: isInitialRangeCheck(allocationRange?.maxValue, allocationRange?.range.max),
      yieldFrom: isInitialRangeCheck(yieldSizeRange?.minValue, yieldSizeRange?.range.min),
      yieldTo: isInitialRangeCheck(yieldSizeRange?.maxValue, yieldSizeRange?.range.max),
      tenorWalYearsFrom: isInitialRangeCheck(tenorWalYearsRange?.minValue, tenorWalYearsRange?.range.min),
      tenorWalYearsTo: isInitialRangeCheck(tenorWalYearsRange?.maxValue, tenorWalYearsRange?.range.max),
      spreadFrom: isInitialRangeCheck(spreadSizeRange?.minValue, spreadSizeRange?.range.min),
      spreadTo: isInitialRangeCheck(spreadSizeRange?.maxValue, spreadSizeRange?.range.max),
      upsized,
    };
  };

  const applyFilters = (values: IAppliedFilters) => {
    const isAllocationZerocase = filtersSelectors.isAllocationZerocase(state);
    const filtersPayload = getFiltersPayload(values);
    let allocationFiltersPayload = {};
    payloadChange(filtersPayload);
    appliedFiltersChange(values);
    if (!isAllocationZerocase) {
      allocationFiltersPayload = getAllocationFiltersPayload(values);
      allocationPayloadChange(allocationFiltersPayload);
    }
    saveLocalFiltersState({
      filters: values,
      filtersPayload,
      ...(!isAllocationZerocase ? { allocationsFilters: { filtersPayload: allocationFiltersPayload } } : {}),
    });
  };

  const resetFilters = () => {
    removeLocalFiltersState();
    const initialState = getInitialState();
    const allocationsInitialState = getAllocationInitialState();
    appliedFiltersChange({
      ...initialState,
      ...allocationsInitialState,
    });
    const filtersPayload = getFiltersPayload(initialState);
    const allocationFiltersPayload = getAllocationFiltersPayload(allocationsInitialState);
    payloadChange(filtersPayload);
    allocationPayloadChange(allocationFiltersPayload);
  };

  const initCrossDealAnalytics = async () => {
    try {
      const initialFiltersState = getInitialState();
      const initialAllocationFiltersState = getAllocationInitialState();
      const initialState = {
        ...initialFiltersState,
        ...initialAllocationFiltersState,
      };

      const filtersState = getLocalFiltersState();
      const appliedFilters = filtersState?.filters || initialState;

      const filtersPayload = filtersState?.filtersPayload || getFiltersPayload(initialState);

      const allocationFiltersPayload =
        filtersState?.allocationsFilters?.filtersPayload || getAllocationFiltersPayload(initialState);

      unstable_batchedUpdates(() => {
        appliedFiltersChange(appliedFilters);
        payloadChange(filtersPayload);
        allocationPayloadChange(allocationFiltersPayload);
      });

      return undefined;
    } catch (errorResponse) {
      NotificationManager.error(getErrorMessage(errorResponse));
      replace('/roadshow');
      return undefined;
    }
  };

  return {
    getCrossDealFilters,
    initCrossDealAnalytics,
    applyFilters,
    resetFilters,
    getLocalFiltersState,
    getInitialState,
    getAllocationInitialState,
    getAccountFilters,
    getSponsorFilters,
    getUnderwriterFilters,
  };
};
