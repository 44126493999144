import React, { useState, useRef, ChangeEvent } from 'react';
import cn from 'classnames';
import { NotificationManager } from '@/Framework/Notification';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { Tooltip, Modal, Button, ButtonVariantType } from '@dealroadshow/uikit';
import { usePermissionsContext } from '@/allocate/application/Allocations/PermissionsContext';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { UPLOAD_MAX_SIZE } from '@/allocate/application/Allocations/Upload/config';
import { tenantDealroadshow } from '@/allocate/application/config';
import { DRS_ALLOCATIONS_ALREADY_EXIST_WARNING } from '@/allocate/ui/components/shared/Upload/constants';

import styles from './uploadButton.scss';

interface IProps {
  label: string,
  className?: string,
}

const UploadButton = ({ label, className }: IProps) => {
  const {
    tenant,
    uploadAllocations,
    checkForUploadedAllocations,
  } = useUploadContext();

  const {
    isAdminAnalyticsOnly,
    isAdminBlacklisted,
  } = usePermissionsContext();

  const [file, setFile] = useState<File>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * @param {Object} event
   */
  const handleFileChoose = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (tenant === tenantDealroadshow) {
      const hasAllocations = await checkForUploadedAllocations();

      if (hasAllocations) {
        setFile(file);
        setIsModalVisible(true);
      } else {
        handleUpload(file);
      }
    } else {
      handleUpload(file);
      inputRef.current.value = '';
    }
  };

  const handleUpload = (file: File) => {
    if (file.size > UPLOAD_MAX_SIZE) {
      inputRef.current.value = '';
      NotificationManager.warning(getMessage(
        messageCodes.UPLOAD_MAX_SIZE,
        {
          size: 5,
          units: 'MB',
        },
      ));
    } else if (file.size === 0) {
      inputRef.current.value = '';
      NotificationManager.error(getMessage(
        messageCodes.UPLOAD_ZERO_SIZE,
        { fileName: file.name },
      ));
    } else {
      uploadAllocations(file);
    }
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    handleUpload(file);
  };

  const handleModalCancel = () => {
    inputRef.current.value = '';
    setIsModalVisible(false);
    setFile(null);
  };

  const isDisabled = isAdminBlacklisted || isAdminAnalyticsOnly;

  return (
    <>
      <Tooltip
        content={ isAdminBlacklisted
          ? 'You are not allowed to upload allocations'
          : 'Not available to "Analytics Only" Deal Managers' }
        disabled={ !isDisabled }
        className={ styles.uploadBtnTooltip }
      >
        <label
          className={ cn(styles.uploadBtn, className, {
            [styles.disabled]: isDisabled,
          }) }
        >
          { label }
          <input
            disabled={ isDisabled }
            type="file"
            accept=".xlsx, .xls"
            onChange={ handleFileChoose }
            ref={ inputRef }
            data-test="allocationsUploadExcelInput"
          />
        </label>
      </Tooltip>
      <Modal
        className={ styles.modal }
        onCloseClicked={ handleModalCancel }
        isVisible={ isModalVisible }
        title="Are You Sure?"
        dataTest="overwriteConfirmationModal"
        footer={ (
          <>
            <Button
              onClick={ handleModalConfirm }
              variant={ ButtonVariantType.action }
              title="Yes"
              dataTest="allocationsUploadConfirmButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              onClick={ handleModalCancel }
              title="Cancel"
              dataTest="allocationsUploadCancelButton"
            />
          </>
        ) }
      >
        { DRS_ALLOCATIONS_ALREADY_EXIST_WARNING }
      </Modal>
    </>
  );
};

export default UploadButton;
