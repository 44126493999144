import { useState, RefObject } from 'react';
import InvestorTargetingRepository from '@/dealroadshow/infrastructure/repository/InvestorTargetingRepository';
import { InvestorTargetingListPayload } from '@/dealroadshow/domain/vo/dmPortal/analytics/InvestorTargeting';
import { useDIContext } from '@/Framework/DI/DIContext';
import downloadFile from '@/Framework/api/downloadFile';
import { NotificationManager } from '@/Framework/Notification';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';

export const useInvestorTargetingPdf = (payload: RefObject<InvestorTargetingListPayload>) => {
  const { container } = useDIContext();
  const investorTargetingRepository = container.get<InvestorTargetingRepository>(InvestorTargetingRepository);

  const [isLoading, setIsLoading] = useState(false);

  const download = async () => {
    setIsLoading(true);
    try {
      const link = await investorTargetingRepository.exportInvestorTargetingList(payload.current);
      downloadFile(link);
    } catch (e) {
      NotificationManager.error(getErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isPdfLoading: isLoading,
    downloadPdf: download,
  };
};
