import React from 'react';
import { Tenant as TenantType } from '@/Framework/Tenant/vo/types/Tenant';
import useRouter from '@/Framework/hooks/useNextRouter';
import TenantContext from '@/Framework/Tenant/TenantContext';
import AccessRestrictedContext from '@/dataroom/application/AccessRestrictedContext';
import ConsentAccessLayer from '@/dataroom/ui/components/dmPortal/Datarooms/Set/ConsentAccessLayer';
import AccessRequestContext from '@/dataroom/application/accessRequests/AccessRequestContext';
import { DataroomTwoFactorAuthenticationAccessLayer }
  from '@/dataroom/ui/components/dmPortal/DataroomTwoFactorAuthentication';

interface IProps {
  children: React.ReactNode,
}

const DataroomLayout = ({ children }: IProps) => {
  const { query: { tenant } } = useRouter();

  return (
    <TenantContext tenant={ tenant as TenantType }>
      <AccessRestrictedContext>
        <ConsentAccessLayer>
          <AccessRequestContext>
            <DataroomTwoFactorAuthenticationAccessLayer tenant={ tenant }>
              { children }
            </DataroomTwoFactorAuthenticationAccessLayer>
          </AccessRequestContext>
        </ConsentAccessLayer>
      </AccessRestrictedContext>
    </TenantContext>
  );
};

export default DataroomLayout;
