import React, { useMemo, useEffect } from 'react';
import cn from 'classnames';
import { Checkbox } from '@dealroadshow/uikit';
import FilterGroup from '@/Framework/UI/Organisms/Filters/components/FilterGroup';
import FilterItem from '@/Framework/UI/Organisms/Filters/components/FilterItem';
import filtersStyles from '@/Framework/UI/Organisms/Filters/filters.scss';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';

interface IProps {
  form: { [key: string]: any },
  formValues: { [key: string]: any },
  fieldComponent: React.ElementType,
  value: string | number,
  groupTitle: string,
  isDisabled: boolean,
  supportIndeterminate?: boolean,
  nestedIds: Array<number | string>,
  children: { [key: string | number]: any },
  activeChildrenCount: number,
  checkedChildrenCount: number,
  filterByName: string,
  filterByChildrenName: string,
  isExpanded?: boolean,
  withCounter?: boolean,
}

function FilterGroupItem({
  form,
  formValues,
  fieldComponent: Field,
  value,
  groupTitle,
  isDisabled,
  supportIndeterminate = true,
  nestedIds,
  children,
  activeChildrenCount,
  checkedChildrenCount,
  filterByName,
  filterByChildrenName,
  withCounter = false,
  isExpanded = false,
}: IProps) {
  const indeterminateState = useMemo(() => (
      supportIndeterminate
        ? getIndeterminateState(children, formValues[filterByChildrenName])
        : {}
    ),
    [formValues[filterByChildrenName], supportIndeterminate]);

  useEffect(() => {
    if (supportIndeterminate && (indeterminateState?.checked !== formValues[filterByName]?.includes(value))) {
      const checkedValues = indeterminateState?.checked
        ? [...formValues[filterByName], value]
        : formValues[filterByName]?.filter((checkedValue) => checkedValue !== value);

      form.change(filterByName, checkedValues);
    }
  }, [indeterminateState]);

  const checkboxComponent = useMemo(() => {
    const handleFilterGroupChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        const nestedUnchecked = children
          .filter(({ value, disabled }) => !formValues[filterByChildrenName]?.includes(value) && !disabled)
          .map(({ value }) => value);
        form.change(filterByChildrenName, [...formValues[filterByChildrenName], ...nestedUnchecked]);
      } else {
        const nestedChecked = children
          .filter(({ value, disabled }) => formValues[filterByChildrenName]?.includes(value) && !disabled)
          .map(({ value }) => value);
        form.change(
          filterByChildrenName,
          formValues[filterByChildrenName]?.filter((value) => !nestedChecked?.includes(value)),
        );
      }
    };

    return (
      <Field
        type="checkbox"
        name={ filterByName }
        dataTest={ filterByName }
        value={ value }
        component={ Checkbox }
        label={ groupTitle }
        supportIndeterminate
        disabled={ isDisabled }
        onChange={ handleFilterGroupChange }
        { ...indeterminateState }
      />
    );
  }, [indeterminateState, children, nestedIds]);

  return (
    <FilterGroup
      key={ value }
      groupId={ value }
      isExpanded={ isExpanded }
      showArrow={ !!(children && children.length) }
      itemsCount={ activeChildrenCount }
      selectedItemsCount={ checkedChildrenCount }
      checkboxComponent={ checkboxComponent }
      withCounter={ withCounter }
    >
      { children.map(({ value, label, disabled }) => (
        <FilterItem
          key={ value }
          className={ cn(filtersStyles.filterItem, filtersStyles.filterNestedItem) }
        >
          <Field
            type="checkbox"
            name={ filterByChildrenName }
            component={ ({ meta, input }) => (
              <Checkbox
                dataTest={ filterByChildrenName }
                { ...input }
                meta={ meta }
                value={ value }
                disabled={ disabled }
                checked={ formValues[filterByChildrenName]?.includes(value) }
                onChange={ (event) => {
                  const { checked } = event.target;
                  if (checked) {
                    form.change(filterByChildrenName, [...formValues[filterByChildrenName], value]);
                  } else {
                    form.change(filterByChildrenName, formValues[filterByChildrenName].filter((id) => id !== value));
                  }
                } }
                label={ label }
              />
            ) }
          />
        </FilterItem>
      )) }
    </FilterGroup>
  );
}

export default FilterGroupItem;
