import React, { useState } from 'react';
import { DataTable, Modal, Button, ButtonVariantType } from '@dealroadshow/uikit';

import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import UploadButton from '@/allocate/ui/components/shared/Upload/UploadButton';
import columns from '@/allocate/ui/components/shared/Upload/UploadExcel/columns';
import styles from '@/allocate/ui/components/shared/Upload/UploadExcel/uploadExcel.scss';

const ErrorsTable = () => {
  const { errors, setDisplayErrors } = useUploadContext();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className={ styles.errors }>
      <div className={ styles.tableHeader }>
        <div className={ styles.title }>Upload Validation Errors</div>
      </div>
      <DataTable
        containerClassName={ styles.tableContainer }
        dataTest="errorsTableDataTable"
        columns={ columns }
        data={ errors }
        isScrollable
      />
      <div className={ styles.tableFooter }>
        <UploadButton
          label="Reupload XLS"
          className={ styles.reuploadBtn }
        />
        <Button
          variant={ ButtonVariantType.text }
          onClick={ () => setIsModalVisible(true) }
          dataTest="errorsTableCancelButton"
        >
          Cancel
        </Button>
      </div>
      <Modal
        className={ styles.modal }
        onCloseClicked={ () => setIsModalVisible(false) }
        isVisible={ isModalVisible }
        title="Are You Sure?"
        dataTest="cancelUploadConfirmationModal"
        footer={ (
          <>
            <Button
              onClick={ () => setDisplayErrors(false) }
              variant={ ButtonVariantType.action }
              title="Yes"
              dataTest="cancelUploadConfirmationModalYesButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              onClick={ () => setIsModalVisible(false) }
              title="Cancel"
              dataTest="cancelUploadConfirmationModalCancelButton"
            />
          </>
        ) }
      >
        Are you sure you want to cancel this upload? Any changes you made will be lost.
      </Modal>
    </div>
  );
};

export default ErrorsTable;
