import { useEffect, useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { messageCodes } from '@/Framework/Message/messages';
import { NotificationManager } from '@/Framework/Notification';
import { useDIContext } from '@/Framework/DI/DIContext';
import { AllocateTenants } from '@/allocate/domain/vo/common/AllocateTenants';
import { usePermissionsContext } from '@/allocate/application/Allocations/PermissionsContext';
import { IUploadError } from '@/allocate/domain/vo/Allocations/Upload/Upload';
import {
  actions,
  allocateEditUrls,
  getAllocateAnalyticsUrl,
  getAllocateUploadUrl,
  getDrsAnalyticsUrl,
  getDrsUploadUrl,
  getUploadUrl,
  isActionInvalid,
  steps,
} from '@/allocate/application/Allocations/Upload/config';
import { ALLOCATE_LIST_URL, AllocationsTypes, tenantAllocate } from '@/allocate/application/config';
import { IFileUploadError } from '@/Framework/UI/Organisms/Upload/interfaces';
import { IUploadProcessing, ProcessingEvent } from '@dealroadshow/file-uploader';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';
import AllocationsAnalyticsRepository from '@/allocate/infrastructure/repository/AllocationsAnalyticsRepository';
import CompaniesRepository from '@/users/infrastructure/repository/CompaniesRepository';

const useUpload = ({
  tenant,
  getRoadshowFilters,
}: {
  tenant: AllocateTenants,
  getRoadshowFilters?: (id: string) => void,
}) => {
  const { container } = useDIContext();
  const { query: { reimportFrom, action, step, ...otherParams }, push } = useRouter();

  const params = {
    ...otherParams,
    roadshowId: otherParams.roadshowId?.toString(),
    dealAllocationId: otherParams.dealAllocationId?.toString(),
  };

  const {
    getCurrentUserRole,
    setIsAdminAnalyticsOnly,
    isAdminBlacklisted,
    isAdminBlacklistedFetched,
    checkIsAdminAnalyticsOnlyByRoadshowId,
  } = usePermissionsContext();

  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);
  const allocationsAnalyticsRepository = container.get<AllocationsAnalyticsRepository>(AllocationsAnalyticsRepository);
  const companiesRepository = container.get<CompaniesRepository>(CompaniesRepository);

  const [allocationsType, setAllocationsType] = useState<AllocationsTypes>(null);
  const [dealAllocationId, setDealAllocationId] = useState<string>(null);
  const [dealAllocationName, setDealAllocationName] = useState<string>(null);
  const [linkedRoadshowId, setLinkedRoadshowId] = useState<string>(null);
  const [linkedRoadshowName, setLinkedRoadshowName] = useState<string>(null);
  const [ownerId, setOwnerId] = useState<number>(null);
  const [ownerName, setOwnerName] = useState<string>(null);

  const [isInitializing, setIsInitializing] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isAllocationsUploading, setIsAllocationsUploading] = useState(false);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [errors, setErrors] = useState<IUploadError[]>(null);
  const [isLeaveModalDisabled, setIsLeaveModalDisabled] = useState(false);
  const [isReimport, setIsReimport] = useState(false);
  const [isReimporting, setIsReimporting] = useState(false);
  const [reimportId, setReimportId] = useState<string>(null);

  /**
   * Initialize upload in Allocate app
   */
  const initAllocateUpload = async (): Promise<void> => {
    if (isAdminBlacklisted) {
      await push(ALLOCATE_LIST_URL);
      NotificationManager.error(getMessage(messageCodes.ALLOCATE_UPLOAD_ACCESS_DENIED));
      return;
    }

    if (reimportFrom) {
      setIsReimport(true);
      setIsReimporting(true);
      setReimportId(reimportFrom?.toString());
    }

    if (step !== steps.uploadExcel.url) {
      await push(getAllocateUploadUrl(steps.uploadExcel));
    }

    setIsAdminAnalyticsOnly(false);
    setIsInitializing(false);
  };

  /**
   * Initialize upload in DRS app
   */
  const initDrsUpload = async (): Promise<void> => {
    try {
      if (step !== steps.uploadExcel.url) {
        await push(getDrsUploadUrl(steps.uploadExcel, params.roadshowId));
      }

      await checkIsAdminAnalyticsOnlyByRoadshowId(params.roadshowId);
      setLinkedRoadshowId(params.roadshowId);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsInitializing(false);
    }
  };

  /**
   * Initialize allocations editing in Allocate app
   */
  const initAllocateEditing = async (): Promise<void> => {
    if (!allocateEditUrls.includes(step?.toString()) || !params.dealAllocationId) {
      push(ALLOCATE_LIST_URL);
      return;
    }

    try {
      const { isAdminAnalyticsOnly } = await getCurrentUserRole(params.dealAllocationId);

      if (isAdminAnalyticsOnly || isAdminBlacklisted) {
        push(ALLOCATE_LIST_URL);
        NotificationManager.error(getMessage(messageCodes.ALLOCATE_EDIT_ACCESS_DENIED));
        return;
      }

      const {
        allocationType,
        dealAllocationName,
        roadshowName,
        roadshowId,
        ownerId,
        isUploaded,
      } = await allocationsAnalyticsRepository.getDealAllocationDetails({
        dealAllocationId: params.dealAllocationId,
      });

      if (!isUploaded) {
        push(ALLOCATE_LIST_URL);
        return;
      }

      if (ownerId) {
        const owner = await companiesRepository.getById(ownerId);
        setOwnerId(ownerId);
        setOwnerName(owner.name);
      }

      setIsEditing(true);
      setAllocationsType(allocationType);
      setDealAllocationId(params.dealAllocationId);
      setDealAllocationName(dealAllocationName);
      setLinkedRoadshowId(roadshowId);
      setLinkedRoadshowName(roadshowName);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      push(ALLOCATE_LIST_URL);
    } finally {
      setIsInitializing(false);
    }
  };

  /**
   * Initialize allocations editing in DRS app
   */
  const initDrsEditing = async (): Promise<void> => {
    const isAdminAnalyticsOnly = await checkIsAdminAnalyticsOnlyByRoadshowId(params.roadshowId);

    if (isAdminAnalyticsOnly || isAdminBlacklisted) {
      push(getDrsAnalyticsUrl(params.roadshowId));
      NotificationManager.error(getMessage(messageCodes.ALLOCATE_EDIT_ACCESS_DENIED));
      return;
    }

    try {
      const {
        allocationType,
        dealAllocationId,
        dealAllocationName,
        roadshowName,
      } = await allocationsAnalyticsRepository.getDealAllocationDetailsByRoadshowId({
        roadshowId: params.roadshowId,
      });

      setIsEditing(true);
      setAllocationsType(allocationType);
      setDealAllocationId(dealAllocationId);
      setDealAllocationName(dealAllocationName);
      setLinkedRoadshowName(roadshowName);
      setLinkedRoadshowId(params.roadshowId);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsInitializing(false);
    }
  };

  /**
   * Sync data from re-imported allocations
   */
  const syncAllocationsData = async (): Promise<void> => {
    try {
      await allocationsUploadRepository.reimportAllocations({
        dealAllocationId,
        oldDealAllocationId: reimportId,
      });

      getSyncedAllocationsData();
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Get data from re-imported allocations
   */
  const getSyncedAllocationsData = async (): Promise<void> => {
    try {
      const {
        dealAllocationName,
        roadshowName,
        roadshowId,
        enhancedTagging: { transactionTypeId },
      } = await allocationsAnalyticsRepository.getDealAllocationDetails({ dealAllocationId });

      if (transactionTypeId) {
        setDealAllocationName(dealAllocationName);
        setLinkedRoadshowName(roadshowName);
        setLinkedRoadshowId(roadshowId);
        setIsReimporting(false);
      } else {
        setTimeout(() => getSyncedAllocationsData(), 1000);
      }
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Handle upload error
   */
  const handleUploadError = (errors: IUploadError[]): void => {
    const displayText = errors.length > 1 ? 'errors' : 'error';
    const message = getMessage(
      messageCodes.EXCEL_UPLOAD_ERRORS_FOUND,
      {
        errorsCount: errors.length,
        displayText,
      },
    );
    NotificationManager.error(message);
    setErrors(errors);
    setDisplayErrors(true);
    setIsAllocationsUploading(false);
  };

  /**
   * Handle upload success
   */
  const handleUploadSuccess = (allocationsType: AllocationsTypes): void => {
    setDisplayErrors(false);
    setErrors(null);
    setIsAllocationsUploading(false);
    setAllocationsType(allocationsType);
    push(getUploadUrl(tenant, steps.confirmSecurityDetails, params.roadshowId));
  };

  /**
   * Upload allocations xls
   */
  const uploadAllocations = async (file: File): Promise<void> => {
    setIsAllocationsUploading(true);
    let upload: IUploadProcessing = await allocationsUploadRepository.upload(file);
    setDealAllocationId(upload.getUuid());

    upload
      .on(ProcessingEvent.processingDone, (response) => handleUploadSuccess(response.data.allocationType))
      .on(ProcessingEvent.error, (response: { data: IUploadError[] } | IFileUploadError) => {
          if ('data' in response) {
            if (!response.data.length) {
              NotificationManager.error(getMessage(messageCodes.UPLOAD_HAS_FAILED));
              setIsAllocationsUploading(false);
            } else {
              handleUploadError(response.data);
            }
          } else if ('error' in response) {
            NotificationManager.error(response.error);
            setIsAllocationsUploading(false);
          }
        },
      );
  };

  /**
   * Check if allocations already uploaded
   */
  const checkForUploadedAllocations = async (): Promise<boolean> => {
    try {
      const details = await allocationsAnalyticsRepository.getDealAllocationDetailsByRoadshowId({
        roadshowId: params.roadshowId,
      });

      return details?.isUploaded;
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return null;
    }
  };

  /**
   * Import Allocations on Allocate
   */
  const importAllocations = async (): Promise<void> => {
    setIsLeaveModalDisabled(true);
    try {
      await allocationsUploadRepository.importAllocations(tenant, {
        dealAllocationId,
        forceImport: false,
        allocationType: allocationsType,
      });

      NotificationManager.success(getMessage(messageCodes.ALLOCATE_ALLOCATIONS_UPLOADED, {
        dealAllocationName,
      }));

      await push(getAllocateAnalyticsUrl(dealAllocationId));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  /**
   * Import Allocations on DRS
   */
  const importDrsAllocations = async (
    ownerId: number,
    roadshowName: string,
    forceImport: boolean = false,
  ): Promise<void> => {
    setIsLeaveModalDisabled(true);
    try {
      await allocationsUploadRepository.importAllocations(tenant, {
        forceImport,
        dealAllocationId,
        ownerId,
        allocationType: allocationsType,
        roadshowId: params.roadshowId,
      });

      NotificationManager.success(getMessage(messageCodes.DRS_ALLOCATIONS_UPLOADED, {
        roadshowName,
      }));

      getRoadshowFilters(params.roadshowId);
      await push(getDrsAnalyticsUrl(params.roadshowId));
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    if (isActionInvalid(action)) {
      push(ALLOCATE_LIST_URL);
      return;
    }

    if (isAdminBlacklistedFetched) {
      if (action === actions.upload) {
        tenant === tenantAllocate
          ? initAllocateUpload()
          : initDrsUpload();
      }

      if (action === actions.edit) {
        tenant === tenantAllocate
          ? initAllocateEditing()
          : initDrsEditing();
      }
    }
  }, [isAdminBlacklistedFetched]);

  return {
    tenant,
    isInitializing,
    isEditing,
    isAllocationsUploading,
    displayErrors,
    errors,
    allocationsType,
    dealAllocationId,
    dealAllocationName,
    linkedRoadshowId,
    linkedRoadshowName,
    ownerId,
    ownerName,
    isLeaveModalDisabled,
    isReimport,
    isReimporting,
    syncAllocationsData,
    checkForUploadedAllocations,
    uploadAllocations,
    setDisplayErrors,
    setDealAllocationName,
    setLinkedRoadshowId,
    setLinkedRoadshowName,
    setOwnerId,
    setOwnerName,
    setIsLeaveModalDisabled,
    importAllocations,
    importDrsAllocations,
  };
};

export default useUpload;
