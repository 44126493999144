import config from '@/Framework/config';
import Application from '@/dmPortal/domain/Application';

export const tenantsConfig = {
  allocate: {
    code: config.tenant.allocate.code,
    path: 'allocate',
    title: 'Allocate',
    isDataroom: false,
  },
  roadshows: {
    code: config.tenant.dealroadshow.code,
    path: 'roadshows',
    title: 'Deal Roadshow',
    isDataroom: false,
  },
  dealvdr: {
    code: config.tenant.tenantDealvdr.code,
    path: 'dealvdr',
    title: 'DealVDR',
    isDataroom: true,
  },
  evercall: {
    code: config.tenant.tenantEvercall.code,
    path: 'evercall',
    title: 'Evercall',
    isDataroom: false,
  },
  investorset: {
    code: config.tenant.tenantInvestorSet.code,
    path: 'investorset',
    title: 'InvestorSet',
    isDataroom: true,
  },
  verisend: {
    code: config.tenant.tenantVeriSend.code,
    path: 'verisend',
    title: 'VeriSend',
    isDataroom: true,
  },
  researchroom: {
    code: config.tenant.tenantResearchRoom.code,
    path: 'researchroom',
    title: 'ResearchRoom',
    isDataroom: true,
  },
  '17g5': {
    code: config.tenant.tenant17g5.code,
    path: '17g5',
    title: '17g5.com',
    isDataroom: true,
  },
};

export const isGlobalAnalyticsVisible = (appCollection, userHasAccess) => Object.values(tenantsConfig).some(
  (tenant) => {
    const isActivated = Application.isTenantActivated(tenant.code, appCollection);
    return isActivated && (tenant.isDataroom ? userHasAccess : true);
  },
);

export const findAvailableTenant = (appCollection, availableApplications) => Object.values(tenantsConfig).find(
  (tenant) => Application.isTenantActivated(tenant.code, appCollection) && (
    tenant.isDataroom ? availableApplications[tenant.code] : true
  ),
);

export const GLOBAL_ANALYTICS_TIMEZONE = 'America/New_York';

export const GLOBAL_ANALYTICS_TIMEZONE_ABBR = 'ET';
