import { Field, useForm } from '@/Framework/UI/Organisms/FinalForm';
import Company from '@/Framework/UI/Organisms/EnhancedTagging/Field/Company';
import TextField from '@/Framework/UI/Organisms/EnhancedTagging/Field/TextField';
import useCompany from '@/Framework/UI/Organisms/EnhancedTagging/EnhancedTaggingContext/useCompany';

const OwnerField = () => {
  const { change } = useForm();
  const companies = useCompany();

  const onSuggestionSelected = (
    value: number,
    fieldName: string,
  ): void => {
    change(fieldName, value);
  };

  const onOwnerChange = (value: string) => {
    if (!value) {
      change('ownerId', null);
    }
  };

  return (
    <>
      <TextField
        name="owner"
        dataTest="ownerField"
        label="Allocate Owner"
        placeholder="Allocation Owner"
        fieldComponent={ Field }
        component={ Company }
        // @ts-ignore
        onSuggestionSelected={ onSuggestionSelected }
        onChange={ onOwnerChange }
        companies={ companies }
        clearOnBlur
      />
    </>
  );
};

export default OwnerField;
