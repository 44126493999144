import React, { createContext, useContext, useMemo } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { INVESTOR_TARGETING } from '@/dealroadshow/ui/components/dmPortal/Analytics/Roadshow/constants';
import { useInvestorTargetingDetailsList } from './useInvestorTargetingDetailsList';
import { useInvestorTargetingActivation } from './useInvestorTargetingActivation';
import { useInvestorTargetingLocalFilters } from './useInvestorTargetingLocalFilters';
import { useInvestorTargetingGlobalFilters } from './useInvestorTargetingGlobalFilters';
import { useInvestorTargetingTargetLists } from './useInvestorTargetingTargetLists';

interface IProps {
  children: React.ReactNode,
}

const checkIsInvestorTargetingPage = (pathname) => {
  const currentRoute = pathname.split('/').pop();

  return currentRoute === INVESTOR_TARGETING;
};

const useInvestorTargeting = () => {
  const { query: { roadshowId: id }, asPath: pathname } = useRouter();
  const roadshowId = id.toString();

  const isInvestorTargetingPage = useMemo(() => checkIsInvestorTargetingPage(pathname), [pathname]);

  const localFilters = useInvestorTargetingLocalFilters({ isInvestorTargetingPage });
  const globalFilters = useInvestorTargetingGlobalFilters({ roadshowId });

  return {
    isInvestorTargetingPage,
    ...localFilters,
    ...globalFilters,
    ...useInvestorTargetingActivation({ roadshowId }),
    ...useInvestorTargetingTargetLists({ roadshowId, comparableLevel: globalFilters.filters.comparableLevel }),
    ...useInvestorTargetingDetailsList({
      roadshowId,
      globalFilters: globalFilters.filters,
      localFilters: localFilters.filter,
    }),
  };
};

type TInvestorTargetingContext = ReturnType<typeof useInvestorTargeting>;

const InvestorTargetingContext = createContext<TInvestorTargetingContext>(null);

export const useInvestorTargetingContext = () => {
  const context = useContext(InvestorTargetingContext);
  if (!context) {
    throw new Error('useInvestorTargetingContext must be used within a InvestorTargetingProvider');
  }
  return context;
};

const InvestorTargetingContextProvider = ({ children }: IProps) => (
  <InvestorTargetingContext.Provider value={ useInvestorTargeting() }>{ children }</InvestorTargetingContext.Provider>
);

export default InvestorTargetingContextProvider;
