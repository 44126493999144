import React from 'react';
import cn from 'classnames';
import { formatSubscriptionLevel, getDigitsAfterDecimalCount } from '@/allocate/ui/components/shared/Tables/helpers';
import { getCurrencySymbol } from '@/allocate/ui/helpers/currencySymbols';
import formatNumber from '@/Framework/dataHelpers/formatters/formatNumber';
import { Icon, IconType } from '@dealroadshow/uikit';
import iconsStyles from '@dealroadshow/uikit/dist/lib/components/Icons/styles.scss';
import styles from '@/allocate/ui/components/shared/Tables/SecurityDetails/SecurityDetailsTable/cellStyles.scss';

export const priceCellCallback = (cellProps) => (
  cellProps.cellData ? (cellProps.cellData * 100).toFixed(5) : '-'
);

export const classSubscriptionLevelCellCallback = (cellProps) => (
  cellProps.cellData ? formatSubscriptionLevel(cellProps.cellData) : '-'
);

export const spreadCellCallback = (cellProps) => {
  const { cellData } = cellProps;

  return cellData || cellData === 0 ? cellData : '-';
};

export const sharePriceCellCallback = (cellProps) => {
  const { cellData, row: { currency } } = cellProps;

  if (cellData) {
    const digitsAfterDecimal = getDigitsAfterDecimalCount(cellData);
    const symbol = getCurrencySymbol(currency.displayName);

    return formatNumber(cellData, {
      symbol,
      symbolOnLeft: !!symbol,
      spaceBetweenValueAndSymbol: !!symbol,
      decimals: Math.max(2, digitsAfterDecimal),
    });
  }

  return '-';
};

export const rangeCellCallback = (cellProps) => {
  const { cellData, row: { currency } } = cellProps;

  if (cellData) {
    const symbol = getCurrencySymbol(currency.displayName);

    return formatNumber(cellData, {
      symbol,
      symbolOnLeft: !!symbol,
      spaceBetweenValueAndSymbol: !!symbol,
      decimals: 2,
    });
  }

  return '-';
};

export const proceedsCellCallback = (cellProps) => {
  const { cellData, row: { currency } } = cellProps;

  if (cellData) {
    const symbol = getCurrencySymbol(currency.displayName);

    return formatNumber(cellData, {
      symbol,
      symbolOnLeft: !!symbol,
      spaceBetweenValueAndSymbol: !!symbol,
    });
  }

  return '-';
};

export const interestOnlyCellCallback = (cellProps) => (cellProps.cellData ? (
  <Icon
    className={ cn(styles.checkIcon, iconsStyles.smallIcon) }
    type={ IconType.check }
  />
) : '');

export const greenShoeCellCallback = (cellProps) => (cellProps.cellData ? (
  <Icon
    className={ cn(styles.checkIcon, iconsStyles.smallIcon) }
    type={ IconType.check }
  />
) : '');
