import config from '@/dataroom/application/config/config';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';

export const AUTO_SEND_ACCESS_REQUEST = 'AUTO_SEND_ACCESS_REQUEST';
export const AUTO_SEND_FOLLOW = 'AUTO_SEND_FOLLOW';

export const getSetsTitle = (tenant: DataroomTenant) => {
  switch (tenant) {
    case config.tenant.tenantVeriSend.code:
      return 'My VeriSends';
    case config.tenant.tenantResearchRoom.code:
      return 'My Research';
    default:
      return 'My Sets';
  }
};
