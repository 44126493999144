import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FilterColumns from '@/Framework/UI/Organisms/Filters/components/FilterColumns';
import { Checkbox } from '@dealroadshow/uikit';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';
import FilterGroupItem from './FilterGroupItem';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (section: string) => void,
}

const filterByName = 'includeIndustryIds';
const filterByChildrenName = 'includeSubIndustryIds';

function Industry({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const { industryFilterList, subIndustryFilterList } = useGlobalAnalyticsFiltersContext();

  const subIndustryById = subIndustryFilterList?.byId;

  const activeSubIndustryIds = useMemo(
    () => subIndustryFilterList?.allIds.filter((subIndustryId) => subIndustryById[subIndustryId]?.canChangeResponse),
    [subIndustryFilterList?.allIds],
  );

  const subIndustryUnchecked = useMemo(
    () => formValues[filterByChildrenName].filter((investorId) => !activeSubIndustryIds.includes(investorId)),
    [formValues[filterByChildrenName]],
  );

  let industryIdsUnchecked = useMemo(
    () => industryFilterList?.allIds.filter((value) => !formValues[filterByName]?.includes(value) &&
      !industryFilterList?.byId[value].canChangeResponse),
    [formValues[filterByName]],
  );

  const allSubIds = useMemo(
    () => subIndustryFilterList?.allIds.map((subIndustryId) => ({
      value: subIndustryId,
      disabled: !subIndustryById[subIndustryId]?.canChangeResponse,
    })),
    [subIndustryFilterList?.allIds],
  );
  const sectionIndeterminateState = useMemo(
    () => getIndeterminateState(allSubIds, formValues[filterByChildrenName]),
    [formValues[filterByChildrenName]],
  );

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        form.change(filterByName, [...formValues[filterByName], ...industryIdsUnchecked]);
        form.change(filterByChildrenName, [...formValues[filterByChildrenName], ...activeSubIndustryIds]);
      } else {
        form.change(filterByName, []);
        form.change(filterByChildrenName, subIndustryUnchecked);
      }
    };

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        disabled={ activeSubIndustryIds.length === 0 }
        { ...sectionIndeterminateState }
      />
    );
  }, [form, subIndustryFilterList?.allIds, sectionIndeterminateState]);

  if (isEmpty(subIndustryFilterList?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { industryFilterList?.allIds.map((value) => (
          <FilterGroupItem
            key={ value }
            form={ form }
            fieldComponent={ Field }
            formValues={ formValues }
            value={ value }
            filterByName={ filterByName }
            filterByChildrenName={ filterByChildrenName }
          />
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default Industry;
