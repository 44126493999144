import { useEffect, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import AllocationsUploadRepository from '@/allocate/infrastructure/repository/AllocationsUploadRepository';
import RoadshowRepository from '@/dealroadshow/infrastructure/repository/RoadshowRepository';
import { IDebtSecurities, IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import { AllocationsTypes, tenantDealroadshow } from '@/allocate/application/config';

const useConfirmSecurityDetails = () => {
  const { container } = useDIContext();
  const { tenant, dealAllocationId, allocationsType, linkedRoadshowId } = useUploadContext();
  const allocationsUploadRepository = container.get<AllocationsUploadRepository>(AllocationsUploadRepository);
  const roadshowRepository = container.get<RoadshowRepository>(RoadshowRepository);

  const [securityDetails, setSecurityDetails] = useState<IDebtSecurities | IEquitySecurities>(null);
  const [isFetching, setIsFetching] = useState(true);

  /**
   * Get transaction type id
   */
  const getTransactionTypeId = async (): Promise<string> => {
    try {
      return await roadshowRepository.getTransactionTypeByRoadshowId({ roadshowId: linkedRoadshowId });
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
      return null;
    }
  };

  /**
   * Get security details
   */
  const getSecurityDetails = async (): Promise<void> => {
    setIsFetching(true);
    try {
      let securityDetails = {};

      if (tenant === tenantDealroadshow) {
        getTransactionTypeId().then((transactionTypeId) => {
          securityDetails = { transactionTypeId };
        });
      }

      if (allocationsType === AllocationsTypes.DEBT) {
        const response = await allocationsUploadRepository.getTemporaryDebtSecurityStats(tenant, {
          dealAllocationId,
          ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
        });
        securityDetails = {
          ...response,
          ...securityDetails,
        };
      }

      if (allocationsType === AllocationsTypes.EQUITY) {
        const response = await allocationsUploadRepository.getTemporaryEquitySecurityStats(tenant, {
          dealAllocationId,
          ...(tenant === tenantDealroadshow && { roadshowId: linkedRoadshowId }),
        });

        securityDetails = {
          ...response,
          ...securityDetails,
        };
      }

      setSecurityDetails(securityDetails as IDebtSecurities | IEquitySecurities);
    } catch (error) {
      NotificationManager.error(getErrorMessage(error));
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (dealAllocationId) {
      getSecurityDetails();
    }
  }, []);

  return {
    securityDetails,
    isFetching,
  };
};

export default useConfirmSecurityDetails;
