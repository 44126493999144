import { useEffect, useState } from 'react';
import Button, { variantTypes } from '@/Framework/UI/Atoms/Button';
import { Input, Modal } from '@dealroadshow/uikit';
import styles from './autoSuggestModal.scss';
import { COMPANY_NAME_MIN_LENGTH, COMPANY_NAME_MAX_LENGTH } from '@/users/domain/company/Company';

interface IProps {
  isVisible: boolean,
  closeModal: () => void,
  title: string,
  text: string,
  label: string,
  name: string,
  handleSaveAccount: (value: string, name: string) => void,
  inputValue: string,
}

const AutoSuggestModal = ({
  isVisible,
  closeModal,
  title,
  text,
  label,
  name,
  handleSaveAccount,
  inputValue,
}: IProps) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (isVisible) setValue(inputValue);
  }, [inputValue]);

  const handleSave = () => {
    handleSaveAccount(value, name);
    handleClose();
  };

  const handleClose = () => {
    closeModal();
    setValue('');
    setError('');
  };

  const handleChange = (event) => {
    const { value } = event.target;
    validateAccountInput(value);
    setValue(value);
  };

  const validateAccountInput = (value) => {
    if (value.length < COMPANY_NAME_MIN_LENGTH) {
      setError(`Suggested ${ name } Name must contain at least ${ COMPANY_NAME_MIN_LENGTH } characters`);
      return false;
    }
    if (value.length > COMPANY_NAME_MAX_LENGTH) {
      setError(`Suggested ${ name } Name may not contain more than ${ COMPANY_NAME_MAX_LENGTH } characters`);
      return false;
    }
    setError('');
    return true;
  };

  return (
    <Modal
      isVisible={ isVisible }
      title={ title }
      onCloseClicked={ handleClose }
      footer={ (
        <>
          <Button
            onClick={ handleSave }
            variant={ variantTypes.action }
            title="Submit"
            dataTest="submitModalButton"
            className={ styles.submitButton }
            disabled={ !!error }
          />
          <Button
            onClick={ handleClose }
            variant={ variantTypes.link }
            title="Cancel"
            dataTest="cancelModalButton"
          />
        </>
      ) }
      dataTest={ `${ name }RoadshowModal` }
    >
      <p className={ styles.text }>{ text }</p>
      <div className={ styles.inputWrapper }>
        <Input
          isNarrow
          type="text"
          label={ label }
          name={ `${ name }InputInRoadshowModal` }
          // @ts-ignore
          input={ {
            name: `${ name }InputInRoadshowModal`,
          } }
          placeholder={ label }
          dataTest={ `${ name }InputInRoadshowModal` }
          value={ value }
          onChange={ handleChange }
          className={ styles.input }
        />
        { error && (
          <div className={ styles.error }>
            { error }
          </div>
        ) }
      </div>
    </Modal>
  );
};

export default AutoSuggestModal;
