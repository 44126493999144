import React from 'react';
import EnhancedTagging from './EnhancedTagging';
import EnhancedTaggingContextProvider from '@/Framework/UI/Organisms/EnhancedTagging/EnhancedTaggingContext';
import TenantContext from '@/Framework/Tenant/TenantContext';

import { tenantDealroadshow } from '@/allocate/application/config';

export default () => (
  <TenantContext tenant={ tenantDealroadshow }>
    <EnhancedTaggingContextProvider>
      <EnhancedTagging />
    </EnhancedTaggingContextProvider>
  </TenantContext>
);
