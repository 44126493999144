import React, { createContext, useContext } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { useDispatch } from 'react-redux';
import { getErrorMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { getAccountTypes } from '@/dealroadshow/application/actions/accountType/data/actions';
import { getRoadshowSettingsById } from '@/dealroadshow/application/actions/data/actions';
import { getDealMetadata } from '@/dealroadshow/application/actions/analytics/metadata/actions';
import { useFiltersContext } from '../FiltersContext';

const useRoadshowAnalytics = () => {
  const { replace } = useRouter();
  const dispatch = useDispatch();

  const { initRoadshowFilters } = useFiltersContext();

  const initRoadshowAnalytics = async (roadshowId: string) => {
    try {
      await dispatch(getAccountTypes());
      await dispatch(getRoadshowSettingsById(roadshowId));

      const metadata: any = await dispatch(getDealMetadata(roadshowId));
      const item = metadata?.collection[0];

      initRoadshowFilters(roadshowId, item?.isPublic);

      return undefined;
    } catch (errorResponse) {
      NotificationManager.error(getErrorMessage(errorResponse));
      replace('/roadshow');
      return undefined;
    }
  };

  return {
    initRoadshowAnalytics,
  };
};

export const RoadshowAnalyticsContext = createContext<ReturnType<typeof useRoadshowAnalytics>>(null);

export const useRoadshowAnalyticsContext = () => {
  const context = useContext(RoadshowAnalyticsContext);
  if (!context) {
    throw new Error('useRoadshowAnalyticsContext must be used within a RoadshowAnalyticsContextProvider');
  }
  return context;
};

const RoadshowAnalyticsContextProvider = ({ children }: { children: React.ReactNode }) => (
  <RoadshowAnalyticsContext.Provider value={ useRoadshowAnalytics() }>{ children }</RoadshowAnalyticsContext.Provider>
);

export default RoadshowAnalyticsContextProvider;
