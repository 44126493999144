import { ReactNode } from 'react';
import { Modal } from '@dealroadshow/uikit';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import { IInputTag } from '@/Framework/UI/Molecules/Form/InputTags/InputTags';
import { IValidationError } from '@/Framework/UI/Organisms/FinalForm/validators/interfaces/ValidationError';
import FormFields from './FormFields';
import { BulkAddDealManagersFormContextProvider } from './context';

import styles from './bulkAddDealManagersModal.scss';

interface IProps<T> {
  onClose: () => void,
  onAdd: (data: T) => void,
  children: ReactNode,
  isVisible?: boolean,
  existEmails?: string[],
  validate?: (values: T) => IValidationError | null,
  name?: string,
  dataTest?: string,
}

const BulkAddDealManagersModal = <T extends { dealManagers: string[] }>({
  onClose,
  onAdd,
  children,
  isVisible = false,
  existEmails = [],
  validate,
  name = 'bulkAddDealManagersForm',
  dataTest = 'bulkAddDealManagers',
}: IProps<T>) => {
  const onSubmit = (data: Omit<T, 'dealManagers'> & { dealManagers: IInputTag[] }) => {
    const payload = {
      ...data,
      dealManagers: data.dealManagers.map(({ value }) => value),
    };

    onAdd(payload as T);
  };

  return (
    <Modal
      title="Bulk Add Deal Managers"
      isVisible={ isVisible }
      dataTest={ `${ dataTest }Modal` }
      className={ styles.modal }
      onCloseClicked={ onClose }
    >
      <BulkAddDealManagersFormContextProvider existEmails={ existEmails }>
        <FinalForm
          name={ name }
          dataTest={ `${ dataTest }Form` }
          validate={ validate }
          onSubmit={ onSubmit }
          render={ () => <FormFields onClose={ onClose }>{ children }</FormFields> }
        />
      </BulkAddDealManagersFormContextProvider>
    </Modal>
  );
};

export default BulkAddDealManagersModal;
