import React from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { CheckApplicationActivity } from '@/dmPortal/application/Layout/NavigationContext';
import { CommonLayoutRoute } from '@/dmPortal/ui/common/constants';
import { findProject } from '@/dmPortal/ui/common/helpers';

interface IProps {
  children: React.ReactNode,
}

const CommonLayout = ({ children }: IProps) => {
  const { pathname } = useRouter();
  const project = findProject(CommonLayoutRoute, pathname);

  return (
    project
      ? <CheckApplicationActivity>{ children }</CheckApplicationActivity>
      : children
  );
};

export default CommonLayout;
