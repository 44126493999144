import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { startOfDay } from 'date-fns';
import Tags from '@/Framework/UI/Organisms/Filters/FiltersPanel/Tags';
import { getFiltersByKeysWithValues } from '@/Framework/UI/Organisms/Filters/helpers';
import { filtersFormatter } from '@/Framework/UI/Organisms/Filters/FiltersPanel/Tags/helpers';
import {
  useFiltersContext,
  calcSelectedAccountsAndInvestor,
} from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import { AppliedFiltersType } from '@/dealroadshow/domain/vo/dmPortal/analytics/Filters';
import { useInvestorTargetingContext } from '@/dealroadshow/application/dmPortal/analytics/InvestorTargetingContext';
import { COMPARABLE_LEVEL_KEY, filtersKeys, removableFilterKeys } from '../constants';
import { getRoadshowTimeZone } from '@/dealroadshow/application/actions/data/selectors';
import utcToZonedDate from '@/Framework/DateTime/utcToZonedDate';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';

interface IProps {
  filters: AppliedFiltersType,
  onTagRemove: (key: string) => void,
}

const SidebarTags = ({ filters: originFilters, onTagRemove }: IProps) => {
  const {
    accounts,
    investors,
    comparableLevel,
    dates: { startedAt },
  } = useFiltersContext();

  const { isVisibleDetails: showComparableFilter } = useInvestorTargetingContext();

  const visibleFilterKeys = showComparableFilter
    ? filtersKeys
    : filtersKeys.filter((key) => key !== COMPARABLE_LEVEL_KEY);

  const timeZone = useSelector(getRoadshowTimeZone);
  const dateFromMin = getUnixTimestamp(startOfDay(utcToZonedDate(timeZone, startedAt)));

  const filters = useMemo(() => {
    const { includeAccountIds, includeInvestorIds } = calcSelectedAccountsAndInvestor({
      accounts,
      investors,
      includeAccountIds: originFilters?.includeAccountIds || [],
      includeInvestorIds: originFilters?.includeInvestorIds || [],
    });

    const selectedComparableLevel = comparableLevel.find((level) => level.value === originFilters.comparableLevel);

    return {
      ...originFilters,
      comparableLevel: selectedComparableLevel ? `Comparable Parent Level: ${ selectedComparableLevel.name }` : null,
      includeInvestorIds: [...includeAccountIds, ...includeInvestorIds],
    };
  }, [originFilters, accounts, investors, comparableLevel]);

  return (
    <Tags
      filters={ getFiltersByKeysWithValues(filters, visibleFilterKeys, dateFromMin) }
      onTagRemove={ onTagRemove }
      filtersFormatter={ filtersFormatter }
      allowedToRemoveFilters={ visibleFilterKeys }
      removableFilters={ removableFilterKeys }
    />
  );
};

export default SidebarTags;
