import { useState } from 'react';
import { IInputTag } from '@/Framework/UI/Molecules/Form/InputTags/InputTags';
import { validateEmail } from '@/Framework/UI/Organisms/FinalForm/validators/user/validateEmail';

const useBulkAddDealManagersForm = (existEmails: string[]) => {
  const [activeStep, setActiveStep] = useState<number>(1);
  const [countInvalidEmails, setCountInvalidEmails] = useState<number>(0);
  const [countExistEmails, setCountExistEmails] = useState<number>(0);
  const [countEmails, setCountEmails] = useState<number>(0);

  const onChangeDealManagers = (values: IInputTag[], callback: (values: IInputTag[]) => void) => {
    let numInvalidEmails = 0;
    let numExistEmails = 0;

    const validateValues = values.map(({ value }) => {
      const isValid = !validateEmail({ value, fieldName: 'tag' });
      const isExist = existEmails.includes(value);

      if (!isValid) {
        numInvalidEmails++;
      }

      if (isExist) {
        numExistEmails++;
      }

      return { value, isValid: isValid && !isExist };
    });

    setCountInvalidEmails(numInvalidEmails);
    setCountExistEmails(numExistEmails);
    setCountEmails(values.length);

    callback(validateValues);
  };

  return {
    activeStep,
    countInvalidEmails,
    countExistEmails,
    countEmails,
    setActiveStep,
    onChangeDealManagers,
  };
};

export default useBulkAddDealManagersForm;
