import { substringFloat } from './substringFloat';
import { generateValidFloatString } from './generateValidFloatString';

/**
 *
 * @param {string | number} value
 * @param {number} fraction
 * @returns {number}
 */

export const roundUpValueToFraction = (value: string | number, fraction: number = 0): number => {
  const entry = generateValidFloatString(value);
  if (!fraction) {
    return substringFloat(entry, 1);
  }
  const mathPow = 10 ** fraction;
  // TS2362: The left-hand side of an arithmetic operation must be of type 'any', 'number', 'bigint' or an enum type.
  // @ts-ignore
  return Number((Math.round(entry * mathPow) / mathPow).toFixed(fraction));
};
