import React from 'react';
import Favicon from '@/dmPortal/ui/common/Favicon';
import AppNavigation from '@/Framework/UI/Templates/AppNavigation';
import Navigation from './Navigation';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import LayoutWithBanner from '@/Framework/UI/Templates/LayoutWithBanner';
import NavigationContext from '@/dmPortal/application/Layout/NavigationContext';
import CheckAccessPermissions from '@/users/application/Session/CheckAccessPermissions';

interface IProps {
  children: React.ReactNode,
}

const Layout = ({ children }: IProps) => {
  const { currentUser, logout, switchToManagerAccess } = useSessionContext();

  return (
    <CheckAccessPermissions onInit={ switchToManagerAccess }>
      <>
        <Favicon />
        <LayoutWithBanner>
          <NavigationContext>
            <AppNavigation
              currentUser={ currentUser }
              contentComponent={ Navigation }
              logoutAction={ () => logout({ redirect: false }) }
            >
              { children }
            </AppNavigation>
          </NavigationContext>
        </LayoutWithBanner>
      </>
    </CheckAccessPermissions>
  );
};

export default Layout;
