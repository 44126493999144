import { Dependencies } from 'constitute';
import UploadDispatcher from '@/dmPortal/application/DI/FileUpload/UploadDispatcher';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import { tenantAllocate } from '@/allocate/application/config';
import { ITemporarySecurityDetailsPayload } from '@/allocate/domain/vo/Allocations/Upload/TemporarySecurityDetails';
import { AllocateTenants } from '@/allocate/domain/vo/common/AllocateTenants';
import { IDebtSecurities, IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import {
  ITemporaryClassnamesRequestPayload,
  ITemporaryAllocationsRequestPayload,
  ITemporaryAllocationsRequestResponse,
} from '@/allocate/domain/vo/Allocations/Upload/TemporaryAllocations';
import { IAllocationsClassesResponse } from '@/allocate/domain/vo/common/AllocationsClasses';
import {
  IGetLeisMappingRequestPayload,
  IInitialCollectionItem as ILeisCollectionItem,
  ISetLeisMappingRequestPayload,
  ICreateAccountRequestPayload,
} from '@/allocate/domain/vo/Allocations/Upload/LinkAccounts';
import { IImportAllocationsRequestPayload } from '@/allocate/domain/vo/Allocations/Upload/ImportAllocations';
import {
  GetRoadshowsRequestPayload,
  GetRoadshowsRequestResponse,
  SaveNameAndLinkRequestPayload,
} from '@/allocate/domain/vo/Allocations/Upload/NameAndLink';
import {
  IGetManagersRequestResponse,
  ISaveDealManagersRequestResponse,
} from '@/allocate/domain/vo/Allocations/Upload/DealManagers';
import { ISaveEnhancedTaggingRequestPayload } from '@/allocate/domain/vo/Allocations/Upload/EnhancedTagging';

@Dependencies(RpcDispatcher, UploadDispatcher)
class AllocationsUploadRepository {
  constructor(
    private rpc: typeof RpcDispatcher,
    private uploadDispatcher: typeof UploadDispatcher,
  ) {}

  /**
   * Upload allocations
   */
  upload(file: File) {
    return this.uploadDispatcher.upload(file, { type: 'ROADSHOW_ALLOCATIONS' });
  }

  /**
   * Get temporary debt security details
   */
  async getTemporaryDebtSecurityStats(
    tenant: AllocateTenants,
    payload: ITemporarySecurityDetailsPayload,
  ): Promise<IDebtSecurities> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_temporary_debt_security_stats'
      : 'roadshow.analytics.deal.get_temporary_debt_security_stats_by_deal_allocation_id';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get temporary equity security details
   */
  async getTemporaryEquitySecurityStats(
    tenant: AllocateTenants,
    payload: ITemporarySecurityDetailsPayload,
  ): Promise<IEquitySecurities> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_temporary_equity_security_stats'
      : 'roadshow.analytics.deal.get_temporary_equity_security_stats_by_deal_allocation_id';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get temporary allocations classes
   */
  async getTemporaryAllocationsClasses(
    tenant: AllocateTenants,
    payload: ITemporaryClassnamesRequestPayload,
  ): Promise<IAllocationsClassesResponse> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_temporary_allocations_class_names'
      : 'roadshow.analytics.deal.get_temporary_allocations_class_names_by_deal_allocation_id';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get temporary allocations
   */
  async getTemporaryAllocations(
    tenant: AllocateTenants,
    payload: ITemporaryAllocationsRequestPayload,
  ): Promise<ITemporaryAllocationsRequestResponse> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_temporary_allocations_stats'
      : 'roadshow.analytics.deal.get_temporary_allocations_stats_by_deal_allocation_id';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get temporary allocations leis accounts mapping
   */
  async getTemporaryLeisAccountsMapping(
    tenant: AllocateTenants,
    payload: IGetLeisMappingRequestPayload,
  ): Promise<ILeisCollectionItem[]> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_temporary_allocation_leis_accounts_mapping'
      : 'roadshow.analytics.deal.get_temporary_allocation_leis_accounts_mapping';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations leis accounts mapping
   */
  async getLeisAccountsMapping(
    tenant: AllocateTenants,
    payload: IGetLeisMappingRequestPayload,
  ): Promise<ILeisCollectionItem[]> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.get_allocation_leis_accounts_mapping'
      : 'roadshow.analytics.deal.get_allocation_leis_accounts_mapping';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Map allocations leis to accounts
   */
  async mapAllocations(
    tenant: AllocateTenants,
    payload: ISetLeisMappingRequestPayload,
  ): Promise<null> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.mapping_allocations_leis_to_accounts'
      : 'roadshow.analytics.deal.mapping_allocations_leis_to_accounts';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Import allocations
   */
  async importAllocations(
    tenant: AllocateTenants,
    payload: IImportAllocationsRequestPayload,
  ) {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.import_securities_and_allocations'
      : 'roadshow.analytics.deal.import_securities_and_allocations';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Request to create account for lei mapping
   */
  async createAccountForLeiMappingRequest(
    tenant: AllocateTenants,
    payload: ICreateAccountRequestPayload,
  ): Promise<null> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.request_to_create_account_for_lei_mapping'
      : 'roadshow.analytics.deal.request_to_create_account_for_lei_mapping';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get available roadshows for current deal manager
   */
  async getRoadshowsList(
    payload: GetRoadshowsRequestPayload,
  ): Promise<GetRoadshowsRequestResponse> {
    const request = new Request('allocate.deal_allocation.get_roadshows_for_manager', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Save name, linked roadshow and owner
   */
  async saveNameAndLink(
    payload: SaveNameAndLinkRequestPayload,
  ): Promise<null> {
    const request = new Request('allocate.deal_allocation.set_name_and_link', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Check linked roadshow data sync status
   */
  async getLinkedRoadshowSyncStatus(payload: {
    dealAllocationId: string,
    roadshowId: string,
  }): Promise<boolean> {
    const request = new Request('allocate.deal_allocation.is_synced', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get deal managers list
   */
  async getManagersList(payload: {
    dealAllocationId: string,
  }): Promise<IGetManagersRequestResponse> {
    const request = new Request('allocate.deal_allocation.get_managers_by_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Save deal managers list
   */
  async saveManagers(
    payload: ISaveDealManagersRequestResponse,
  ): Promise<null> {
    const request = new Request('allocate.deal_allocation.set_deal_managers', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Save Enhanced tagging data
   */
  async saveEnhancedTagging(
    payload: ISaveEnhancedTaggingRequestPayload,
  ): Promise<null> {
    const request = new Request('allocate.deal_allocation.set_enhanced_tagging', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Reimport allocations
   */
  async reimportAllocations(payload: {
    dealAllocationId: string,
    oldDealAllocationId: string,
  }): Promise<null> {
    const request = new Request('allocate.deal_allocation.reimport_securities_and_allocations', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }
}

export default AllocationsUploadRepository;
