import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useFiltersContext } from '@/dealroadshow/application/dmPortal/analytics/FiltersContext';
import { Checkbox } from '@dealroadshow/uikit';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import FilterColumns from '@/Framework/UI/Organisms/Filters/components/FilterColumns';
import FilterGroupItem from './FilterGroupItem';
import { getIndeterminateState } from '@/Framework/UI/Organisms/Filters/helpers';

interface IProps {
  fieldComponent: React.ElementType,
  form: any,
  formValues: any,
  sectionId: string,
  sectionTitle: string,
  isVisible: boolean,
  onSectionToggle: (id: string) => void,
}

const filterByName = 'includeAccountIds';
const filterByChildrenName = 'includeInvestorIds';

function Accounts({
  form,
  formValues,
  fieldComponent: Field,
  sectionId,
  sectionTitle,
  isVisible,
  onSectionToggle,
}: IProps) {
  const { accounts, investors } = useFiltersContext();

  const accountsById = accounts?.byId;
  const investorById = investors?.byId;

  const {
    activeAccountsIds,
    allAccountIds,
  } = useMemo(
    () => ({
      activeAccountsIds: accounts?.allIds.filter((accountId) => accountsById[accountId]?.canChangeResponse),
      allAccountIds: accounts?.allIds.map((accountId) => ({
        value: accountId,
        disabled: !accountsById[accountId]?.canChangeResponse,
      })),
    }),
    [accounts?.allIds],
  );

  const activeInvestorIds = useMemo(
    () => investors?.allIds.filter((investorId) => investorById[investorId]?.canChangeResponse),
    [investors?.allIds],
  );

  const headerComponent = useMemo(() => {
    const handleFilterSectionChange = (event) => {
      const { checked } = event.target;
      if (checked) {
        let accountsIdsUnchecked = accounts?.allIds
          .filter((value) => !formValues[filterByName]?.includes(value) && accounts?.byId[value]?.canChangeResponse)
          .map((value) => value);
        let investorIdsUnchecked = investors?.allIds
          .filter(
            (value) => !formValues[filterByChildrenName]?.includes(value) && investors?.byId[value]?.canChangeResponse,
          )
          .map((value) => value);
        form.change(filterByName, [...formValues[filterByName], ...accountsIdsUnchecked]);
        form.change(filterByChildrenName, [...formValues[filterByChildrenName], ...investorIdsUnchecked]);
      } else {
        const accountIdsChecked = formValues[filterByName]?.filter(
          (accountId) => !activeAccountsIds?.includes(accountId),
        );
        const investorIdsChecked = formValues[filterByChildrenName]?.filter(
          (investorId) => !activeInvestorIds?.includes(investorId),
        );
        form.change(filterByName, accountIdsChecked);
        form.change(filterByChildrenName, investorIdsChecked);
      }
    };

    const sectionIndeterminateState = getIndeterminateState(allAccountIds, formValues[filterByName]);

    return (
      <Checkbox
        name={ sectionId }
        dataTest={ sectionId }
        label={ sectionTitle }
        supportIndeterminate
        onChange={ handleFilterSectionChange }
        disabled={ activeAccountsIds.length === 0 }
        { ...sectionIndeterminateState }
      />
    );
  }, [
    form,
    allAccountIds,
    activeAccountsIds,
    activeInvestorIds,
    formValues[filterByName],
    formValues[filterByChildrenName],
  ]);

  if (isEmpty(accounts?.allIds)) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <FilterColumns>
        { accounts?.allIds.map((value) => (
          <FilterGroupItem
            key={ value }
            form={ form }
            fieldComponent={ Field }
            formValues={ formValues }
            value={ value }
            filterByName={ filterByName }
            filterByChildrenName={ filterByChildrenName }
          />
        )) }
      </FilterColumns>
    </FilterSection>
  );
}

export default Accounts;
