import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { components as ReactSelectComponents } from 'react-select';

import {
  useGlobalAnalyticsFiltersContext,
} from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import FilterSection from '@/Framework/UI/Organisms/Filters/components/FilterSection';
import useFilterMethods from '@/dmPortal/ui/components/GlobalAnalytics/common/Sidebar/Sections/useFilterMethods';
import FinalFormSelect from '@/Framework/UI/Molecules/Form/Select/legacy/FinalFormSelect';
import { IAppliedFilters } from '@/dmPortal/application/GlobalAnalytics/filters/types';
import styles from './company.scss';

interface IProps {
  sectionTitle: string,
  sectionId: string,
  isVisible: boolean,
  onSectionToggle: () => void,
  fieldComponent: React.ElementType,
  filtersPayload: IAppliedFilters,
}

const filterByName = 'includeSponsorIds';

const CompanyParent = (props) => {
  const {
    sectionId,
    sectionTitle,
    isVisible,
    onSectionToggle,
    fieldComponent: Field,
    filtersPayload,
  }: IProps = props;
  const {
    sponsorFilterList,
    getSponsorFilters,
    isFetching,
    isSponsorFetching,
  } = useGlobalAnalyticsFiltersContext();

  const {
    isFirstRender,
    formatOptionLabel,
    handleSearchChange,
    handleOnScrollBottom,
  } = useFilterMethods(filtersPayload, getSponsorFilters, sponsorFilterList);

  const headerComponent = () => (
    <label data-test={ sectionId } htmlFor={ sectionId } className={ styles.labelWrapper }>
      <div className={ styles.title }>
        { sectionTitle }
      </div>
    </label>
  );

  const options = useMemo(() => sponsorFilterList?.map((sponsor) => {
    return {
      value: `${ sponsor?.sponsorId }`,
      label: `${ sponsor?.sponsorName }`,
      canChangeResponse: sponsor?.canChangeResponse,
    };
  }), [sponsorFilterList]);

  const abbreviationValueRenderer = ({ data, ...otherProps }) => (
    // @ts-ignore
    <ReactSelectComponents.MultiValueLabel { ...otherProps }>
      { data.label }
    </ReactSelectComponents.MultiValueLabel>
  );

  if (isEmpty(sponsorFilterList) && isFirstRender.current) {
    return null;
  }

  return (
    <FilterSection
      sectionId={ sectionId }
      headerComponent={ headerComponent() }
      isVisible={ isVisible }
      onSectionToggle={ onSectionToggle }
    >
      <Field
        name={ filterByName }
        component={ FinalFormSelect }
        placeholder="Select Company / Parent"
        options={ options }
        isMulti
        components={ {
          MultiValueLabel: abbreviationValueRenderer,
        } }
        isNarrow
        hideSelectedOptions={ false }
        closeMenuOnSelect={ false }
        dataTest="FilterCompanyParentSelect"
        onInputChange={ handleSearchChange }
        isLoading={ isFetching && isSponsorFetching }
        formatOptionLabel={ formatOptionLabel }
        isOptionDisabled={ (option) => (isFetching && isSponsorFetching) || !option.canChangeResponse }
        onMenuScrollToBottom={ handleOnScrollBottom }
      />
    </FilterSection>
  );
};

export default CompanyParent;
