import { useMemo, useState } from 'react';
import { Link } from '@/Framework/Router/Next/Link';
import { Icon, IconType, Button, ButtonVariantType } from '@dealroadshow/uikit';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { ICollectionItem, TCollectionItemsFields } from '@/allocate/domain/vo/Allocations/Upload/DealManagers';
import { BulkAddDealManagersModal } from '@/dmPortal/ui/common/Modals/BulkAddDealManagersModal';
import BulkAddForm from './BulkAddForm';

import styles from '../dealManagers.scss';

type TCollectionItemModal = Omit<ICollectionItem, 'corporateEmail'> & { dealManagers: string[] };

interface IProps {
  fields: TCollectionItemsFields,
}

const Buttons = ({ fields }: IProps) => {
  const { linkedRoadshowId } = useUploadContext();
  const [isShowModal, setIsShowModal] = useState(false);

  const existEmails = useMemo(() => fields.value.map(({ corporateEmail }) => corporateEmail), [fields]);

  const onBulkAddDealManagers = ({
    dealManagers,
    inviteByEmail = false,
    analyticsOnly = false,
  }: TCollectionItemModal) => {
    dealManagers.forEach((corporateEmail) => {
      fields.push({ corporateEmail, inviteByEmail, analyticsOnly });
    });
    setIsShowModal(false);
  };

  const onAddDealManager = () => {
    fields.push({ corporateEmail: '', inviteByEmail: false, analyticsOnly: false });
  };

  if (linkedRoadshowId) {
    return (
      <>
        <Link to={ `/roadshow/${ linkedRoadshowId }/edit` } target="_blank">
          <Button
            variant={ ButtonVariantType.outline }
            dataTest="enhancedTaggingAllocateModifyButton"
            className={ styles.modifyMobileButton }
          >
            Modify on Linked Roadshow
          </Button>
        </Link>
        <div className={ styles.modifyMessage }>
          Changes to Deal Managers must be done through the roadshow
        </div>
      </>
    );
  }

  return (
    <div className={ styles.dealManagersButtonContainer }>
      <Button
        variant={ ButtonVariantType.link }
        onClick={ onAddDealManager }
        dataTest="dealManagerAddButton"
      >
        <Icon type={ IconType.add } />
        Add Manager
      </Button>
      <Button
        variant={ ButtonVariantType.link }
        dataTest="dealManagerBulkAddButton"
        onClick={ () => setIsShowModal(true) }
      >
        <Icon type={ IconType.addUser } />
        Bulk Add Managers
      </Button>

      { isShowModal && (
        <BulkAddDealManagersModal<TCollectionItemModal>
          isVisible
          onClose={ () => setIsShowModal(false) }
          onAdd={ onBulkAddDealManagers }
          existEmails={ existEmails }
        >
          <BulkAddForm />
        </BulkAddDealManagersModal>
      ) }
    </div>
  );
};

export default Buttons;
