import { Dependencies } from 'constitute';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import RpcDispatcher from '@/dmPortal/application/DI/Rpc/HttpDispatcher';
import Request from '@/Framework/api/Rpc/Request';
import { AllocationsTypes, tenantAllocate } from '@/allocate/application/config';
import { IAllocationsDetails } from '@/allocate/domain/vo/Allocations/Analytics/AllocationsDetails';
import { ISecurityDetailsRequestPayload, IDebtSecurities, IEquitySecurities } from '@/allocate/domain/vo/Allocations/Analytics/SecutiryDetails';
import { IAllocationsByAccountTypeRequestPayload, IAllocationsByAccountTypeResponse } from '@/allocate/domain/vo/Allocations/Analytics/AllocationsByAccountType';
import { IAllocationsByAccountRequestPayload, IAllocationsByAccountResponse } from '@/allocate/domain/vo/Allocations/Analytics/AllocationsByAccount';
import { IAllocationsClassesResponse } from '@/allocate/domain/vo/common/AllocationsClasses';
import { IGetAbsentAccountsRequestPayload, IGetAbsentAccountsRequestResponse } from '@/allocate/domain/vo/Allocations/Analytics/AbsentAccounts';
import { AllocateTenants } from '@/allocate/domain/vo/common/AllocateTenants';
import { ICorrectionPayload } from '@/allocate/domain/vo/Allocations/Analytics/Correction';
import { AllocateRole } from '@/allocate/application/Allocations/PermissionsContext/constants';

@Dependencies(RpcDispatcher)
class AllocationsAnalyticsRepository {
  constructor(private rpc: typeof RpcDispatcher) {}

  /**
   * Check if admin have analytics only permissions by dealAllocationId
   */
  async getCurrentUserRole(payload: {
    dealAllocationId?: string,
  }): Promise<{
    isAdmin: boolean,
    currentAllocateRole: AllocateRole,
  }> {
    const request = new Request('allocate.deal_allocation.current_role_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations details by dealAllocationId
   */
  async getDealAllocationDetails(payload: {
    dealAllocationId: string,
  }): Promise<IAllocationsDetails> {
    const request = new Request('allocate.deal_allocation.get_details_by_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations details by roadshowId
   */
  async getDealAllocationDetailsByRoadshowId(payload: {
    roadshowId: string,
  }): Promise<IAllocationsDetails> {
    const request = new Request('allocate.deal_allocation.get_details_by_roadshow_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get debt security stats
   */
  async getDebtSecurityStats(
    payload: ISecurityDetailsRequestPayload,
  ): Promise<IDebtSecurities> {
    const request = new Request('allocate.deal_allocation.get_debt_security_stats_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get equity security stats
   */
  async getEquitySecurityStats(
    payload: ISecurityDetailsRequestPayload,
  ): Promise<IEquitySecurities> {
    const request = new Request('allocate.deal_allocation.get_equity_security_stats_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations classes
   */
  async getAllocationsClasses(payload: {
    dealAllocationId: string,
    allocationType: AllocationsTypes,
  }): Promise<IAllocationsClassesResponse> {
    const request = new Request('allocate.deal_allocation.get_allocations_class_names_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations count
   */
  async getAllocationsCount(payload: {
    dealAllocationId: string,
    allocationType: AllocationsTypes,
  }): Promise<number> {
    const request = new Request('allocate.deal_allocation.get_count_allocations_accounts_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations stats by account type
   */
  async getAllocationsByAccountType(
    payload: IAllocationsByAccountTypeRequestPayload,
  ): Promise<IAllocationsByAccountTypeResponse> {
    const request = new Request('allocate.deal_allocation.get_allocations_stats_by_account_type', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get allocations stats by account
   */
  async getAllocationsByAccount(
    payload: IAllocationsByAccountRequestPayload,
  ): Promise<IAllocationsByAccountResponse> {
    const request = new Request('allocate.deal_allocation.get_allocations_stats_by_account', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get absent accounts classes
   */
  async getAbsentAccountsClasses(payload: {
    dealAllocationId: string,
  }): Promise<string[]> {
    const request = new Request('allocate.absent_accounts.get_tab_names', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Get absent accounts list
   */
  async getAbsentAccounts(
    payload: IGetAbsentAccountsRequestPayload,
  ): Promise<IGetAbsentAccountsRequestResponse> {
    const request = new Request('allocate.absent_accounts.get_list', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Download source allocations xls file
   */
  async downloadSourceFileByDealId(payload: {
    dealAllocationId: string,
  }): Promise<string> {
    const request = new Request('allocate.deal_allocation.get_allocation_source_file_url_by_deal_allocation_id', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Remove allocations
   */
  async removeAllocations(payload: {
    dealAllocationId: string,
    allocationType: AllocationsTypes,
    withBackup?: boolean,
  }): Promise<void> {
    const request = new Request('allocate.deal_allocation.remove_securities_and_allocations', payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }

  /**
   * Submit correction
   */
  async submitCorrection(
    tenant: AllocateTenants,
    payload: ICorrectionPayload,
  ): Promise<void> {
    const path = tenant === tenantAllocate
      ? 'allocate.deal_allocation.send_correction_notification'
      : 'roadshow.notification.send_correction_notification';
    const request = new Request(path, payload);
    const response = await this.rpc.request<RpcSuccess>(request);
    return response.getResult().payload;
  }
}

export default AllocationsAnalyticsRepository;
