import { useCallback, useMemo } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { Link } from '@/Framework/Router/Next/Link';
import DealManagersRows from '@/allocate/ui/components/shared/Upload/DealManagers/DealManagersRows';
import FinalForm, { FieldArray } from '@/Framework/UI/Organisms/FinalForm';
import { Tooltip, Button, ButtonVariantType } from '@dealroadshow/uikit';
import validate from '@/allocate/ui/components/shared/Upload/DealManagers/validator';
import { useUploadContext } from '@/allocate/application/Allocations/Upload/UploadContext';
import { useSessionContext } from '@/users/application/Session/SessionContext';
import StepWrapper from '@/allocate/ui/components/shared/Upload/StepWrapper';
import useDealManagers from '@/allocate/ui/components/shared/Upload/DealManagers/useDealManagers';
import { ICollectionItem } from '@/allocate/domain/vo/Allocations/Upload/DealManagers';
import { steps, getUploadUrl, getCancelEditingUrl, changeEditingStep } from '@/allocate/application/Allocations/Upload/config';
import { TFinalFormRenderProp } from '@/Framework/UI/Organisms/FinalForm/interfaces';

import styles from '@/allocate/ui/components/shared/Upload/DealManagers/dealManagers.scss';

const DealManagers = () => {
  const { query: { from }, push } = useRouter();
  const { currentUser } = useSessionContext();

  const {
    tenant,
    isEditing,
    dealAllocationId,
    linkedRoadshowId,
  } = useUploadContext();

  const {
    isFetching,
    managersList,
    saveManagers,
    isSubmitting,
  } = useDealManagers();

  const initialValues = useMemo(() => [{
    corporateEmail: currentUser?.email,
    inviteByEmail: false,
    analyticsOnly: false,
  }], [currentUser]);

  const onSubmit = useCallback(({ dealManagers }: { dealManagers: ICollectionItem[] }) => {
    saveManagers(dealManagers);
  }, [dealAllocationId]);

  const onBackButtonClicked = useCallback(async () => {
    if (isEditing) {
      changeEditingStep(steps.nameAndLink, dealAllocationId, from?.toString());
    } else {
      await push(getUploadUrl(tenant, steps.nameAndLink));
    }
  }, [isEditing, dealAllocationId]);

  const renderForm: TFinalFormRenderProp = useCallback(({
    hasValidationErrors,
    invalid,
  }) => (
    <>
      <FieldArray name="dealManagers" component={ DealManagersRows } />
      <div className={ styles.buttons }>
        <Button
          variant={ ButtonVariantType.text }
          dataTest="dealManagersBackButton"
          onClick={ onBackButtonClicked }
          className={ styles.backButton }
        >
          Back
        </Button>
        <div>
          { isEditing && (
            <Link to={ getCancelEditingUrl(tenant, from?.toString(), dealAllocationId) }>
              <Button
                variant={ ButtonVariantType.text }
                className={ styles.cancelButton }
                dataTest="dealManagersCancelButton"
              >
                Cancel
              </Button>
            </Link>
          ) }
          { !!linkedRoadshowId && (
            <Tooltip content="Changes to Deal Managers must be done through the roadshow">
              <Link to={ `/roadshow/${ linkedRoadshowId }/edit` } target="_blank">
                <Button
                  variant={ ButtonVariantType.outline }
                  dataTest="enhancedTaggingAllocateModifyButton"
                  className={ styles.modifyButton }
                >
                  Modify on Linked Roadshow
                </Button>
              </Link>
            </Tooltip>
          ) }
          <Button
            type="submit"
            className={ styles.saveButton }
            variant={ ButtonVariantType.action }
            onClick={ () => hasValidationErrors }
            dataTest="dealManagersSubmitButton"
            disabled={ isSubmitting || invalid }
          >
            { isEditing ? 'Save & Continue' : 'Continue' }
          </Button>
        </div>
      </div>
    </>
  ), [
    currentUser,
    dealAllocationId,
    linkedRoadshowId,
    managersList,
    isSubmitting,
    isEditing,
  ]);

  return (
    <StepWrapper isFetching={ isFetching }>
      <div className={ styles.title }>
        Deal Managers
      </div>
      <div className={ styles.table }>
        <FinalForm
          dataTest="DealManagersAllocationsForm"
          initialValues={
            { dealManagers: managersList.length ? managersList : initialValues }
          }
          className={ styles.form }
          onSubmit={ onSubmit }
          render={ renderForm }
          validate={ validate }
          name="dealManagers"
        />
      </div>
    </StepWrapper>
  );
};

export default DealManagers;
