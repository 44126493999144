import { useEffect, useState } from 'react';
import { getErrorMessage, getMessage } from '@/Framework/Message/Mapper/getMessage';
import { NotificationManager } from '@/Framework/Notification';
import { useDIContext } from '@/Framework/DI/DIContext';
import ApplicationRepository from '@/dmPortal/infrastructure/repository/ApplicationRepository';
import { messageCodes } from '@/Framework/Message/messages';

const useNavigation = () => {
  const [applicationCollection, setApplicationCollection] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState([]);
  const [isCollectionFetching, setIsCollectionFetching] = useState(false);
  const [isCollectionFetched, setIsCollectionFetched] = useState(false);

  /**
   * @type {ApplicationRepository}
   */
  const applicationRepository = useDIContext().container.get<ApplicationRepository>(ApplicationRepository);

  useEffect(() => {
    getApplications();
  }, []);

  /**
   *
   * @return {function(...[*]=)}
   */
  const getApplications = async() => {
    setIsCollectionFetching(true);
    try {
      const response = await applicationRepository.getApplications();
      setApplicationCollection(response);
      setIsCollectionFetching(false);
      setIsCollectionFetched(true);
      return response;
    } catch (err) {
      NotificationManager.error(getErrorMessage(err));
      setIsCollectionFetched(true);
      setIsCollectionFetching(false);
      return undefined;
    }
  };

  const enableApplication = async (tenant: string) => {
    const applicationIndex = applicationCollection.findIndex((el) => el.tenant === tenant);
    const { name } = applicationCollection[applicationIndex];

    setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: true }));

    try {
      await applicationRepository.enableApplication(tenant);
      refreshApplication(tenant);

      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
      NotificationManager.success(getMessage(messageCodes.ENABLE_APPLICATION_SUCCESS, { applicationName: name }));
    } catch (e) {
      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
      NotificationManager.error(getErrorMessage(e));
    }
  };

  const disableApplication = async (tenant: string) => {
    const applicationIndex = applicationCollection.findIndex((el) => el.tenant === tenant);
    const { name } = applicationCollection[applicationIndex];

    setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: true }));
    try {
      await applicationRepository.disableApplication(tenant);

      refreshApplication(tenant);
      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
      NotificationManager.success(getMessage(messageCodes.DISABLE_APPLICATION_SUCCESS, { applicationName: name }));
    } catch (e) {
      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
       NotificationManager.error(getErrorMessage(e));
    }
  };

  const refreshApplication = async (tenant: string) => {
    const applicationIndex = applicationCollection.findIndex((el) => el.tenant === tenant);

    setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: true }));
    try {
      applicationCollection[applicationIndex] = await applicationRepository.getApplication(tenant);
      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
    } catch (e) {
      setIsRefreshing((prevState) => ({ ...prevState, [applicationIndex]: false }));
      NotificationManager.error(getErrorMessage(e));
    }
  };

  return {
    isRefreshing,
    isCollectionFetching,
    isCollectionFetched,
    applicationCollection,
    enableApplication,
    disableApplication,
    getApplications,
    refreshApplication,
  };
};

export default useNavigation;
