import React from 'react';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import SharedTags from '@/Framework/UI/Organisms/Filters/FiltersPanel/Tags';
import { getFiltersByKeysWithValues } from '@/Framework/UI/Organisms/Filters/helpers';
import { filtersFormatter } from '@/Framework/UI/Organisms/Filters/FiltersPanel/Tags/helpers';
import { filtersKeys } from '@/dmPortal/application/GlobalAnalytics/filters/constants';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';
import { GLOBAL_ANALYTICS_TIMEZONE } from '@/dmPortal/application/GlobalAnalytics/config';

const Tags = (props: any) => {
  const { firstLoginAt } = useGlobalAnalyticsFiltersContext();
  const dateFromMin = firstLoginAt && getUnixTimestamp(new Date(firstLoginAt));

  return (
    <>
      <SharedTags
        { ...props }
        filters={ getFiltersByKeysWithValues(props.filters, filtersKeys, dateFromMin, GLOBAL_ANALYTICS_TIMEZONE) }
        filtersFormatter={ filtersFormatter }
        allowedToRemoveFilters={ filtersKeys }
      />
    </>
  );
};

export default Tags;
