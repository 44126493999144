import React from 'react';
import SharedDatePeriod from '@/Framework/UI/Organisms/Filters/FiltersPanel/DatePeriod';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import {
  useDatePeriod,
} from '@/Framework/DateTime/hooks/useDatePeriod';
import { GLOBAL_ANALYTICS_TIMEZONE } from '@/dmPortal/application/GlobalAnalytics/config';
import getUnixTimestamp from '@/Framework/DateTime/getUnixTimestamp';

interface IProps {
  dateFrom?: number,
  dateTo?: number,
  onDateFromChange: (date: number) => void,
  onDateToChange: (date: number) => void,
}

const DatePeriod = ({ dateFrom, dateTo, onDateFromChange, onDateToChange }: IProps) => {
  const { firstLoginAt } = useGlobalAnalyticsFiltersContext();
  const dateFromMin = getUnixTimestamp(new Date(firstLoginAt));

  const datePeriodProps = useDatePeriod({
    dateToMax: getUnixTimestamp(),
    dateFromMin,
    timeZone: GLOBAL_ANALYTICS_TIMEZONE,
    dateFrom: dateFrom ?? dateFromMin,
    dateTo: dateTo ?? getUnixTimestamp(),
    onDateFromChange,
    onDateToChange,
  });

  return (
    <SharedDatePeriod { ...datePeriodProps } />
  );
};

export default DatePeriod;
