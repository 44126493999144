import React from 'react';
import { Field } from '@/Framework/UI/Organisms/FinalForm';
import { CheckboxInput } from '@dealroadshow/uikit';
import { useGlobalAnalyticsFiltersContext } from '@/dmPortal/application/GlobalAnalytics/GlobalAnalyticsFiltersContext';
import styles from '../../allocations.scss';

const Upsized = () => {
  const {
    debtUpsizedFilter: { canChangeResponse },
  } = useGlobalAnalyticsFiltersContext();

  return (
    <div className={ styles.upsizedField }>
      <Field
        name="upsized"
        label="Show Upsized Deals Only"
        type="checkbox"
        disabled={ !canChangeResponse }
        component={ CheckboxInput }
        dataTest="upsized"
      />
    </div>
  );
};

export default Upsized;
