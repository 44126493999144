import { useRef, useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import { getTenantInitialCollectionMapping } from '@/dmPortal/application/crossDealAnalytics/common/helpers';
import DataroomRepository from '@/dataroom/infrastructure/repository/DataroomCrossDealAnalyticsRepository';
import config from '@/dataroom/application/config/config';

const tenantCollection = [
  config.tenant.tenantDealvdr.code,
  config.tenant.tenant17g5.code,
  config.tenant.tenantInvestorSet.code,
  config.tenant.tenantVeriSend.code,
  config.tenant.tenantResearchRoom.code,
];

const useHasAccess = () => {
  const { container } = useDIContext();
  const userHasAccess = useRef<{ [key in DataroomTenant]: boolean }>(
    getTenantInitialCollectionMapping<DataroomTenant, boolean>(tenantCollection, false),
  );
  const [isFetched, setIsFetched] = useState(false);

  const checkHasAccessByTenant = async (tenant: DataroomTenant) => {
    try {
      const dataroomRepository = container.get<DataroomRepository>(DataroomRepository);
      const response = await dataroomRepository.hasUserAccessToCrossDealActivity(tenant, { filters: {} });
      userHasAccess.current[tenant] = response.userHasAccess;
    } catch (error) {
      userHasAccess.current[tenant] = false;
    }
  };

  const getHasAccessApplications = async (): Promise<void> => {
    await Promise.all(tenantCollection.map(checkHasAccessByTenant));
    setIsFetched(true);
  };

  return {
    availableApplications: userHasAccess.current,
    getHasAccessApplications,
    isFetched,
  };
};

export default useHasAccess;
