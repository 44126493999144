import React, { useEffect, useState } from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import { useNavigationContext } from '@/dmPortal/application/Layout/NavigationContext/index';
import PagePreloader from '@/dmPortal/ui/components/PagePreloader';
import Application from '@/dmPortal/domain/Application';

interface IProps {
  children: React.ReactNode,
}

const CheckApplicationActivity = ({ children }: IProps) => {
  const [isVisibleChildren, setIsVisibleChildren] = useState(false);
  const { push, asPath } = useRouter();
  const {
    applicationCollection,
    isCollectionFetched: isApplicationCollectionFetched,
  } = useNavigationContext();

  const checkApplicationEnabled = async () => {
    if (
      !applicationCollection.some((app) => asPath.startsWith(app.uri) && Application.isActivated(app.state))
    ) {
      push('/applications');
      setIsVisibleChildren(false);
    } else {
      setIsVisibleChildren(true);
    }
  };

  useEffect(() => {
    if (isApplicationCollectionFetched) {
      checkApplicationEnabled();
    }
  }, [isApplicationCollectionFetched]);

  if (!isVisibleChildren) {
    return <PagePreloader />;
  }

  return isVisibleChildren && children;
};

export default CheckApplicationActivity;
