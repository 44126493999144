import { groupValidators } from '@/Framework/UI/Organisms/FinalForm/validators/helpers';
import { validateFormSection } from '@/Framework/UI/Organisms/FinalForm/validators/validateFormSection';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateDealSize } from '@/Framework/UI/Organisms/FinalForm/validators/validateDealSize';
import { validateFieldsArrayForFinalForm } from '@/Framework/UI/Organisms/FinalForm/validators/validateFieldsArrayForFinalForm';
import { validateDuplicate } from '@/Framework/UI/Organisms/FinalForm/validators/validateDuplicate';
import { validateRequiredField } from '@/Framework/UI/Organisms/FinalForm/validators/validateRequiredField';

const paramsUnderwriters = [
  {
    fieldName: 'name',
    fieldCaption: 'Underwriter Name',
    functionValidation: [
      {
        name: validateDuplicate,
        params: { listName: 'Underwriter / Advisor' },
      },
    ],
  },
];

export default (values) => groupValidators(
  validateFormSection(values, 'enhancedTagging', (values) => ({
    ...(validateRequiredField({
      fieldName: 'transactionTypeId',
      fieldCaption: 'Transaction Type',
      value: values.transactionTypeId,
    })),
    ...!!values && values?.sponsorName !== '' ? validateMinLength({
      fieldName: 'sponsorName',
      fieldCaption: 'Company / Parent',
      value: values.sponsorName,
      length: 3,
    }) : null,
    ...!!values && values?.sponsorName !== '' ? validateMaxLength({
      fieldName: 'sponsorName',
      fieldCaption: 'Company / Parent',
      value: values.sponsorName,
      length: 128,
    }) : null,
    ...!!values && values?.dealSize !== '' ? validateDealSize({
      fieldName: 'dealSize',
      fieldCaption: 'Deal size',
      value: values.dealSize,
    }) : null,
    ...(validateFieldsArrayForFinalForm(
      'underwriters',
      paramsUnderwriters,
      { collection: values.underwriters },
    )),
  })),
);
