import React from 'react';
import useRouter from '@/Framework/hooks/useNextRouter';
import DataroomLayout from '@/dmPortal/ui/components/Dataroom/DataroomLayout';
import GlobalAnalytics from '@/dmPortal/ui/components/GlobalAnalytics';
import RoadshowAnalyticsLayout from '@/dealroadshow/ui/components/dmPortal/Analytics/Roadshow/common/Layout';
import Allocate from '@/allocate/ui/components/Allocate/Allocate';
import { findProject } from '@/dmPortal/ui/common/helpers';
import { RouteList } from '@/dmPortal/ui/common/constants';

const mapLayoutComponents = {
  [RouteList.Datarooms]: DataroomLayout,
  [RouteList.GlobalAnalytics]: GlobalAnalytics,
  [RouteList.RoadshowAnalytics]: RoadshowAnalyticsLayout,
  [RouteList.Allocate]: Allocate,
};

interface IProps {
  children: React.ReactNode,
}

const ProjectLayout = ({ children }: IProps) => {
  const { pathname } = useRouter();

  const project = findProject(RouteList, pathname);

  const LayoutComponent = mapLayoutComponents[project];

  return LayoutComponent
    ? <LayoutComponent>{ children }</LayoutComponent>
    : children;
};

export default ProjectLayout;
